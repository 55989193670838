import { useRootStore } from 'hooks'
import Wrapper from 'pages/layout'
import { CSVDownload } from 'react-csv'
import { Button, Card, Container, Icon } from 'tabler-react'
import { formatDateToLocalString } from 'utils/dateFormat'
import CoachStudentList from '../../components/Students/CoachStudentList'
import { UseGetCoachStudents } from '../../modules/coach/hooks/UseGetCoachStudents'

const FightersPageCSV = () => {
  const { data, loading, loadData } = UseGetCoachStudents()
  const { currentUser, currentCoachTeam } = useRootStore()
  console.log(currentUser, currentCoachTeam)
  let students = []
  if (data?.students && !loading) {
    students =
      data?.students.map(
        ({
          id,
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          email,
          current_balance,
          last_jump,
          last_tunnel_session,
          last_event
        }) => ({
          id,
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          email,
          current_balance,
          last_jump: formatDateToLocalString(last_jump),
          last_tunnel_session: formatDateToLocalString(last_tunnel_session),
          last_event: formatDateToLocalString(last_event)
        })
      ) ?? []
  }

  if (loading) {
    return (
      <Button loading color="secondary">
        Processing...
      </Button>
    )
  }

  return (
    <>
      {students.length > 0 && (
        <CSVDownload data={students} filename="fighters.csv" />
      )}
      <Button
        outline
        onClick={loadData}
        icon="download-cloud"
        color="secondary"
        disabled={loading}
      >
        CSV
      </Button>
    </>
  )
}

const FightersPage = (props) => {
  return (
    <Wrapper {...props} title="Fighters">
      <Container>
        <Card>
          <Card.Header>
            <Card.Title>
              <Icon name="users" className="mr-2 ml-0 text-blue" />
              Fighters
            </Card.Title>
            <Card.Options>
              <Button.List align="right">
                <FightersPageCSV />
              </Button.List>
            </Card.Options>
          </Card.Header>
        </Card>
        <Card>
          <Card.Body>
            <CoachStudentList />
          </Card.Body>
        </Card>
      </Container>
    </Wrapper>
  )
}

export default FightersPage
