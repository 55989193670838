//import LocationSearchField from 'components/Students/Forms/LocationSearchField'
import { Nav } from 'tabler-react'

const DefaultNav = () => {
  return (
    <>
      <Nav.Item icon="home" to="/" value="/" />
      {/* hide location search for later
      <li className="nav-item ml-lg-auto mb-4 mb-lg-0">
        <LocationSearchField />
      </li>
      */}
    </>
  )
}

export default DefaultNav
