export const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

//todo: add other domain support
export const getEnvironmentFromHostname = (hostname: string) => {
  const parts = hostname.split('.')

  // tslint:disable-next-line:triple-equals
  if (parts.length === 3 && parts[0] === 'portal' && parts[1] === 'skycru') {
    return 'production'
  }

  // tslint:disable-next-line:triple-equals
  if (parts.length === 4 && parts[0] === 'portal' && parts[2] === 'skycruapp') {
    return parts[1]
  }

  if (hostname === 'localhost') {
    return 'local'
  }

  return 'unknown'
}
