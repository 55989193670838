import React from "react";
import { Card, Header, Text } from "tabler-react";
import moment from "moment";
import { Types } from '../../types/graphql'


/**
 * Props for the Greeting component.
 */
interface OrgCredentialMembershipCardProps {
  /**
   * membership entry for org
   */
  membershipCredential: Types.OrgCredential;
  /**
   * org item
   */
  org: Types.TeamOrg;
}

const OrgCredentialMembershipCard = ({
  org,
  membershipCredential,
}: OrgCredentialMembershipCardProps) => {
  return (
    <Card>
      <Card.Body>
        <Header.H5>{org.name}</Header.H5>
        <Text className={""}>
          Membership: <strong>{membershipCredential.membership}</strong>
        </Text>
        <Text className={""}>
          Expires:{" "}
          <span
            className={`${
              moment(membershipCredential.expires).isBefore(moment())
                ? "text-danger"
                : "text-success"
            }`}
          >
            {membershipCredential.expires}
          </span>
        </Text>
      </Card.Body>
    </Card>
  );
};

export default OrgCredentialMembershipCard;
