import { useQuery } from '@apollo/client'
import { GET_TEAM_REGISTRATION_STATUSES } from 'graphql/GET_TEAM_REGISTRATION_STATUSES'
import { GET_TEAM_REGISTRATION_STATUSES as GET_TEAM_REGISTRATION_STATUSES_TYPE } from 'graphql/types/GET_TEAM_REGISTRATION_STATUSES'
import { useMemo } from 'react'

const useGetRegistrationStatus = (teamId: number) => {
  const { data: statusData } = useQuery<GET_TEAM_REGISTRATION_STATUSES_TYPE>(
    GET_TEAM_REGISTRATION_STATUSES,
    {
      variables: { teamId },
      skip: !teamId
    }
  )

  const teamStatusOptions = useMemo(() => {
    const first = 'confirmed'
    const second = 'pending'
    return (
      statusData?.teamStatusList
        .filter((stat) => stat.is_exists)
        .sort(function (x, y) {
          return x.type === first
            ? -1
            : y.type === first
            ? 1
            : x.type === second
            ? -1
            : y.type === second
            ? 1
            : 0
        }) ?? []
    )
  }, [statusData?.teamStatusList])

  const mappedStatusOptions = teamStatusOptions.map((stat) => {
    return {
      id: stat.id,
      value: stat.name,
      label: stat.name,
      type: stat.type ? stat.type.toLowerCase() : ''
    }
  })

  return {
    teamStatusOptions,
    mappedStatusOptions
  }
}

export default useGetRegistrationStatus
