import { RootStoreContext } from 'contexts'
import { useContext } from 'react'
import { Avatar, Grid, Icon, Text } from 'tabler-react'
import useRouter from 'use-react-router'

interface IRecentCoachListLItemProps {
  coach: any
}

const MAX_TEXT_LENGTH = 25

const RecentCoachListLItem = ({ coach }: IRecentCoachListLItemProps) => {
  const rootstore = useContext(RootStoreContext)
  const { history } = useRouter()
  return (
    <li className="list-separated-item">
      <Grid.Row className="align-items-center">
        <Grid.Col auto>
          <Avatar
            status={coach.registration_id && 'success'}
            size="md"
            className="d-block"
            imageURL={
              (coach.profile_avatar &&
                `/api/s3/uploads/${coach.profile_avatar}`) ||
              '//www.gravatar.com/avatar?d=mp'
            }
          />
        </Grid.Col>
        <Grid.Col>
          <div>
            <span
              className="cursor-pointer"
              onClick={() => {
                rootstore.loadCoach(coach)
                history.push(`/admin/coach-details`)
              }}
            >
              {coach.first_name} {coach.last_name}
            </span>
          </div>
          <Text.Small className="d-block">
            <span className="mr-2 text-muted">{coach.id}</span>
            <a href={coach.email ? 'mailto:' + coach.email : '#'}>
              <Icon
                name="mail"
                className={coach.email ? 'mr-2' : ' text-muted mr-2'}
              />
            </a>
            <a href={coach.phone_number ? 'tel:' + coach.phone_number : '#'}>
              <Icon
                name="phone"
                className={coach.phone_number ? 'mr-2' : ' text-muted mr-2'}
              />
            </a>
          </Text.Small>
        </Grid.Col>
        <Grid.Col auto>
          <Icon name="more-vertical" />
        </Grid.Col>
      </Grid.Row>
    </li>
  )
}

export default RecentCoachListLItem
