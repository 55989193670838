import { useMutation } from '@apollo/client';
import InvoiceEditJobQuantity from 'components/Invoices/InvoiceEditJobQuantity';
import InvoiceEditJobUnitPrice from 'components/Invoices/InvoiceEditJobUnitPrice';
import JobNotesEditForm from 'components/Jobs/JobNotesEditForm';
import { EUserTypes } from 'components/User/userTypes';
import { DELETE_INVOICE_ITEM } from 'graphql/DELETE_INVOICE_ITEM';
import { GET_INVOICE } from 'graphql/GET_INVOICE';
import { GET_INVOICE_ITEMS } from 'graphql/GET_INVOICE_ITEMS';
import { GET_JOB_LIST } from 'graphql/GET_JOB_LIST';
import { UPDATE_INVOICE } from 'graphql/UPDATE_INVOICE';
import { UPDATE_JOB } from 'graphql/UPDATE_JOB';
import { useRootStore } from 'hooks';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Table, Tag, Text } from 'tabler-react';
import { formatMoney } from 'utils/numberFormat';
import { Types } from '../../types/graphql'

interface InvoiceDetailJobItemProps {
  job: Types.Job;
  invoice: Types.Invoice;
}

const InvoiceDetailJobItem = ({
  job,
  invoice
}: InvoiceDetailJobItemProps) => {
  const invoice_amount = invoice.paymentDetail.total / 100
  const { isStudent } = useGetCurrentUserType();
  const { currentUser } = useRootStore();

  const [isEdittingQty, setIsEdittingQty] = useState(false);
  const [isEdittingUnitPrice, setIsEdittingUnitPrice] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [updateInvoice] = useMutation(UPDATE_INVOICE);
  const [deleteInvoiceJob] = useMutation(DELETE_INVOICE_ITEM);

  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: async () => {
      await updateInvoice({
        variables: {
          updateInvoiceInput: {
            id: invoice.id,
            invoice_amount: invoice_amount - job.unit_price * job.quantity
          }
        },
        refetchQueries: [
          {
            query: GET_INVOICE,
            variables: {
              id: invoice.id
            }
          },
          {
            query: GET_INVOICE_ITEMS,
            variables: {
              invoice_id: invoice.id
            }
          }
        ]
      });
    }
  });

  const statusHandleClick = async () => {
    if (!window.confirm('Remove job from this invoice? This job will be added back to the Service Dashboard.')) {
      return false;
    }

    await deleteInvoiceJob({
      variables: {
        itemId: job.id
      }
    });

    await updateJob({
      variables: {
        updateJobInput: {
          id: job.id,
          status: 'Complete'
        }
      },
      refetchQueries: [
        {
          query: GET_JOB_LIST,
          variables: {
            jobListFilter: {
              invoice_id: invoice.id
            }
          }
        }
      ]
    });
    toast.success(`Item removed!`);
  };

  return (
    <Table.Row>
      <Table.Col>
        <span
          className="cursor-pointer"
          onClick={() => {
            if (isStudent) {
              return;
            }
            setIsOpen(true);
          }}
        >
          <span>
            {job.name}
          </span>
          {job.priority === 'Rush' && (
            <span className="ml-3 text-muted">({job.priority})</span>
          )}
          <Text.Small className="d-block text-muted">
            Intake: {moment(job.created_on).format('D MMM, yyyy')}
            {job?.user_gear?.gear_model?.name &&
              ' | ' + job?.user_gear?.gear_model?.name}{' '}
            {job?.user_gear?.gear_canopy_size > 0 &&
              job?.user_gear?.gear_canopy_size}
            {job?.user_gear?.gear_serial && (
              <> | S/N: {job?.user_gear?.gear_serial}</>
            )}
            {job?.user_gear?.gear_dom && (
              <>
                {' | D.O.M. '}{' '}
                {moment(job?.user_gear?.gear_dom).format('MMM yyyy')}
              </>
            )}
            {!isStudent &&
            <Tag className="ml-2">{job.job_storage}</Tag>
            }
          </Text.Small>
        </span>
        <Text.Small className="text-muted cursor-pointer">
          <span
            onClick={() => {
              if (isStudent) {
                return;
              }
              setIsOpen(true);
            }}
          >
            {job.job_notes}
          </span>
        </Text.Small>
      </Table.Col>
      <Table.Col></Table.Col>
      <Table.Col className="text-center">
        {isEdittingUnitPrice ? (
          <InvoiceEditJobUnitPrice
            job={job}
            invoice={invoice}
            setIsEditting={setIsEdittingUnitPrice}
          />
        ) : (
          <span
            className={'cursor-pointer'}
            onClick={() => {
              if (isStudent) {
                return;
              }
              setIsEdittingUnitPrice(true);
            }}
          >
            {formatMoney(job.unit_price)}
          </span>
        )}
      </Table.Col>
      <Table.Col className="text-center">
        {isEdittingQty ? (
          <InvoiceEditJobQuantity
            job={job}
            invoice={invoice}
            setIsEditting={setIsEdittingQty}
          />
        ) : (
          <span
            className={'cursor-pointer'}
            onClick={() => {
              if (isStudent) {
                return;
              }
              setIsEdittingQty(true);
            }}
          >
            {job?.quantity}
          </span>
        )}
      </Table.Col>
      <Table.Col className="text-right">
        {formatMoney(job.unit_price * job?.quantity)}
        {currentUser.type !== EUserTypes.student && (
          <Button
            icon="x"
            color="white"
            size="sm"
            className={`ml-3 pt-0 text-muted d-print-none`}
            onClick={() => statusHandleClick()}
          />
        )}
        <JobNotesEditForm
          job={job}
          invoice_id={invoice.id}
          isModalOpen={isOpen}
          toggleModal={setIsOpen}
        />
      </Table.Col>
    </Table.Row>
  );
};

export default InvoiceDetailJobItem;
