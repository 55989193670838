import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container } from 'tabler-react';
import useReactRouter from 'use-react-router';
import SiteLogo from './SiteLogo'
// import miniLogo from '../../assets/images/SKYCRU_favicon-100x100.png';
import logo from '../../assets/images/logo.png';

interface HeaderProps {
  collapse: boolean;
  setCollapse: (collapse: boolean) => void;
}

const HeaderVisitor: FC<HeaderProps> = ({ collapse, setCollapse }) => {
  const { history } = useReactRouter();

  return (
    <div className="header py-4">
      <Container>
      <div className="d-flex justify-content-between">
        <span
              className="header-toggler d-lg-none ml-lg-0 cursor-pointer"
              onClick={() => setCollapse(!collapse)}
            >
              <span className="header-toggler-icon" />
            </span>
          <Link className="header-brand" to="/">
          {SiteLogo ? (
            <SiteLogo customClass={'d-none d-lg-block'} />
          ) : (
            <img src={logo} className="d-none d-lg-block" alt="logo" />
          )}
            {/* hide temporarily, logo on mobile browsers
            <img
              alt="SKYCRU logo"
              className="d-lg-none d-md-block"
              src={miniLogo}
              style={{ maxHeight: '32px' }}
            />
            */}
          </Link>
          <div className="d-flex">
            <Button
              outline
              icon="user"
              color="primary"
              size="sm"
              className="pl-3 pr-4"
              onClick={() => history.push(`/login`)}
            >
              LOGIN
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default observer(HeaderVisitor);
