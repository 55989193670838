import { useMutation, useQuery } from '@apollo/client'
import { domainActivities } from 'components/constants'
import { GET_SPORT_TYPES } from 'graphql/GET_SPORT_TYPES'
import { GET_TEAM_SPORTS } from 'graphql/GET_TEAM_SPORTS'
import { INSERT_DELETE_TEAM_SPORTS } from 'graphql/INSERT_DELETE_TEAM_SPORTS.'
import { GET_SPORT_TYPES as GET_SPORT_TYPES_TYPE } from 'graphql/types/GET_SPORT_TYPES'
import { GET_TEAM_SPORTS as GET_TEAM_SPORTS_TYPE } from 'graphql/types/GET_TEAM_SPORTS'
import { useRootStore } from 'hooks'
import {
  SPORT_TYPE_LOCATION_MAPPING,
  SportRowType
} from 'modules/sport-type/constants'
import { LocationType } from 'modules/team/constants/api'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Button, Card, Dimmer } from 'tabler-react'

const TeamActivitiesList = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const { domainData } = useRootStore()

  const { data: teamSportsData, loading: teamSportsLoading } =
    useQuery<GET_TEAM_SPORTS_TYPE>(GET_TEAM_SPORTS, {
      variables: {
        team_id: teamId
      }
    })

  const teamSportIds = useMemo<number[]>(
    () =>
      teamSportsData?.teamSports.map((teamSport) => teamSport.sport_type_id) ??
      [],
    [teamSportsData?.teamSports, teamSportsLoading]
  )

  const [majorActivityIds, setMajorActivityIds] = useState<number[]>([])
  const [activities, setActivities] = useState<
    {
      sportTypeId: number
      locationType: LocationType
    }[]
  >([])

  const [insertDeleteTeamSports, { loading }] = useMutation(
    INSERT_DELETE_TEAM_SPORTS,
    {
      onCompleted: () => {
        toast.success('Activities updated successfully')
      }
    }
  )

  // If activities length is greater than 0, set the majorActivityIds to the activities
  useEffect(() => {
    if (activities.length > 0) {
      setMajorActivityIds(activities.map((activity) => activity.sportTypeId))
    }
  }, [activities])

  const { data: sportTypesData, loading: sportTypesLoading } =
    useQuery<GET_SPORT_TYPES_TYPE>(GET_SPORT_TYPES)

  const sportTypeRows = useMemo(
    () =>
      sportTypesData?.getSportTypes
        .filter(
          (sportType) =>
            sportType.sub === null &&
            domainActivities[domainData.key].includes(sportType.id)
        )
        ?.map(({ id, name, slug, sub }) => ({
          id,
          name,
          slug,
          sub,
          hasSub: sportTypesData?.getSportTypes.some(
            (sportType) => sportType.sub === id
          )
        })) ?? [],
    [sportTypesData?.getSportTypes, domainData]
  )

  const subSportTypeRows = useMemo(() => {
    if (majorActivityIds.length === 0) {
      return []
    }

    return majorActivityIds.flatMap(
      (majorActivityId) =>
        sportTypesData?.getSportTypes
          .filter((sportType) => sportType.sub === majorActivityId)
          ?.map(({ id, name }) => ({
            id,
            name,
            hasSub: sportTypesData?.getSportTypes.some(
              (sportType) => sportType.sub === id
            )
          })) ?? []
    )
  }, [majorActivityIds, sportTypesData?.getSportTypes])

  // If teamSportIds length is greater than 0, set the activities to the teamSportIds
  useEffect(() => {
    if (teamSportIds.length > 0) {
      setActivities(
        teamSportIds.map((sportTypeId) => {
          const sub = sportTypesData?.getSportTypes.find(
            (sportType) => sportType.id === sportTypeId
          )?.sub

          const locationType = SPORT_TYPE_LOCATION_MAPPING.get(
            sub ?? sportTypeId
          ) as LocationType

          return {
            sportTypeId,
            locationType
          }
        })
      )
    }
  }, [teamSportIds, sportTypesData?.getSportTypes])

  const onSelectActivity = ({ id: sportTypeId, hasSub }: SportRowType) => {
    const sub = sportTypesData?.getSportTypes.find(
      (sportType) => sportType.id === sportTypeId
    )?.sub

    const locationType = SPORT_TYPE_LOCATION_MAPPING.get(
      sub ?? sportTypeId
    ) as LocationType

    const activityIndex = activities.findIndex(
      (activity) => activity.sportTypeId === sportTypeId
    )

    if (activityIndex === -1) {
      setActivities((prev) => [
        ...prev,
        {
          sportTypeId,
          locationType
        }
      ])

      setMajorActivityIds((prev) => {
        if (!hasSub) {
          return prev
        }

        if (prev.includes(sportTypeId)) {
          return prev.filter((id) => id !== sportTypeId)
        }

        return [...prev, sportTypeId]
      })
    } else {
      // filter activities by locationType
      const filteredActivities = activities.filter(
        (activity) => activity.locationType === locationType
      )

      if (hasSub && filteredActivities.length > 1) {
        return
      }

      setMajorActivityIds((prev) => prev.filter((id) => id !== sportTypeId))

      setActivities(
        activities.filter((activity) => activity.sportTypeId !== sportTypeId)
      )
    }
  }

  return (
    <Card statusColor="blue" title="Activities">
      <Card.Body>
        <Dimmer active={sportTypesLoading} loader={sportTypesLoading}>
          <Button.List align="center">
            {sportTypeRows.map((sportType) => {
              return (
                <Button
                  pill
                  color={
                    activities.some(
                      (activity) => activity.sportTypeId === sportType.id
                    )
                      ? 'primary'
                      : 'secondary'
                  }
                  size="sm"
                  key={sportType.id}
                  onClick={async (e) => {
                    e.preventDefault()

                    onSelectActivity(sportType)
                  }}
                >
                  {sportType.name}
                </Button>
              )
            })}
          </Button.List>

          {subSportTypeRows.length > 0 && (
            <Button.List align="center" className="my-4">
              {subSportTypeRows.map((sportType) => {
                return (
                  <Button
                    pill
                    color={
                      activities.some(
                        (activity) => activity.sportTypeId === sportType.id
                      )
                        ? 'primary'
                        : 'secondary'
                    }
                    size="sm"
                    key={sportType.id}
                    onClick={(e) => {
                      e.preventDefault()

                      onSelectActivity(sportType)
                    }}
                  >
                    {sportType.name}
                  </Button>
                )
              })}
            </Button.List>
          )}
        </Dimmer>
      </Card.Body>

      <Card.Footer>
        <Button.List align="right">
          <Button
            disabled={loading}
            loading={loading}
            pill
            color="gray-dark"
            size="sm"
            onClick={async (e) => {
              e.preventDefault()

              const sportTypeIds = activities.map(
                (activity) => activity.sportTypeId
              )

              await insertDeleteTeamSports({
                variables: {
                  team_id: parseInt(teamId),
                  sport_type_ids: sportTypeIds
                },
                refetchQueries: [
                  {
                    query: GET_TEAM_SPORTS,
                    variables: {
                      team_id: teamId
                    }
                  }
                ]
              })
            }}
          >
            UPDATE
          </Button>
        </Button.List>
      </Card.Footer>
    </Card>
  )
}

export default TeamActivitiesList
