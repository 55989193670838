import { getEnvironmentFromHostname } from 'utils/misc'

export enum ENodeEnvironment {
  PRODUCTION = '',
  DEVELOPMENT = 'local',
  STAGING = 'staging'
}

const useGetNodeEnvironment = () => {
  const env = getEnvironmentFromHostname(window.location.hostname)
  if (env === 'production') {
    return ENodeEnvironment.PRODUCTION
  }

  if (env === 'local') {
    return ENodeEnvironment.DEVELOPMENT
  }

  return ENodeEnvironment.STAGING
}

export default useGetNodeEnvironment
