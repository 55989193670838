import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Grid } from 'tabler-react'
import { Types } from '../../types/graphql'
import RigCardItem from './RigCardItem'
import RigModal from './RigModal'

interface IRigCardProps {
  rigs: Types.Rig[]
  search?: string
}

const RigCard = ({ rigs, search }: IRigCardProps) => {
  // UserRig states
  const [rig, setRig] = useState<Types.Rig>(null)
  const [isRigModalOpen, setIsRigModalOpen] = useState<boolean>(false)

  const handleRigToggleModal = (isOpen) => {
    setIsRigModalOpen(isOpen)
  }
  return (
    <>
      <RigModal
        rig={rig}
        toggleModal={handleRigToggleModal}
        isModalOpen={isRigModalOpen}
      />
      <Grid.Row>
        {rigs &&
          rigs
            .filter(
              (r) =>
                (search && r.name.toLowerCase().indexOf(search) !== -1) ||
                !search
            )
            .map((userRig) => (
              <Grid.Col xl={4} lg={6} md={6} sm={12} xs={12} key={userRig.id}>
                <RigCardItem
                  rig={userRig}
                  toggleModal={handleRigToggleModal}
                  setRig={setRig}
                />
              </Grid.Col>
            ))}
      </Grid.Row>
    </>
  )
}

export default observer(RigCard)
