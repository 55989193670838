import { useRootStore } from 'hooks'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import LoginPostForm from '../components/User/Forms/LoginPostForm'
import SiteLogo, { SiteFavicon } from './layout/SiteLogo'

const LoginPage = (props: any) => {
  const { domainData } = useRootStore()
  return (
    <>
      <SiteFavicon />
      <Helmet>
        <title>{domainData.name}</title>
      </Helmet>
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className="col col-login mx-auto">
                <div className="text-center mb-6">
                  <Link to="/">
                    <SiteLogo />
                  </Link>
                </div>
                <LoginPostForm {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage
