import RegisteredStudentsListItem from 'components/Calendar/RegisteredStudentsListItem'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import useGetRegisteredStudentsCount from 'modules/team-registration-status/hooks/useGetRegisteredStudentsCount'
import React, { useMemo } from 'react'
import { Alert, Card, Dimmer, Grid, Header, Text } from 'tabler-react'
import useRouter from 'use-react-router'
import { Types } from '../../types/graphql'

type EventRegistrationStatusCountProps = {
  event: Types.Camp
}

const EventRegistrationStatusCount = ({
  event
}: EventRegistrationStatusCountProps) => {
  const { history } = useRouter()

  const { isStudent } = useGetCurrentUserType()

  const { data, loading } = useGetRegisteredStudentsCount(parseInt(event.id))

  const hasRegisteredStudents = event.registered_students.length > 0

  let registrationStatuses = []
  if (data?.getRegisteredStudentsCount) {
    registrationStatuses = [...data.getRegisteredStudentsCount]
  }

  const statusSorted = useMemo(() => {
    // Sort the status by type
    // Confirmed, Pending, Waitlist
    return registrationStatuses.sort((a, b) => {
      const aType = a.type && a?.type.toLowerCase()
      const bType = b.type && b?.type.toLowerCase()
      if (aType === 'confirmed') {
        return -1
      }
      if (bType === 'confirmed') {
        return 1
      }

      if (aType === 'pending') {
        return -1
      }
      if (bType === 'pending') {
        return 1
      }

      if (aType === 'waitlist') {
        return -1
      }
      if (bType === 'waitlist') {
        return 1
      }

      return 0
    })
  }, [registrationStatuses])

  return (
    <Dimmer active={loading}>
      <span
        className="cursor-pointer"
        onClick={() => history.push(`/event/registration-list/${event.id}`)}
      >
        {!isStudent && (
          <Card.Header className="p-5">
            <Grid.Row>
              {statusSorted.map((status, index) => {
                const statusType = status.type && status?.type.toLowerCase()
                const isStatusTypeConfirmed = statusType === 'confirmed'
                if (statusType === 'confirmed' || statusType === 'pending') {
                  return (
                    <Grid.Col width={6} className="text-center mb-5" key={index}>
                      <Header.H2 className="text-gray-dark">
                        {status.total}
                        {isStatusTypeConfirmed &&
                          event.registrations_max &&
                          event.registrations_max !== '0' && (
                            <span className="text-muted">
                              {'/' + event.registrations_max}
                            </span>
                          )}
                      </Header.H2>
                      <Text
                        className={`text-${
                          status.color === 'success' ? 'success' : 'gray-dark'
                        } text-uppercase`}
                      >
                        {status.name}
                      </Text>
                    </Grid.Col>
                  )
                } else {
                  return (
                    <Grid.Col width={12} className="" key={index}>
                      <Grid.Row className="text-muted">
                        <Grid.Col width={10}>
                        {status.name}:
                        </Grid.Col>
                        <Grid.Col width={2} className="text-center">
                        {status.total}
                        </Grid.Col>
                      </Grid.Row>

                    </Grid.Col>
                  )
                }
              })}
            </Grid.Row>
          </Card.Header>
        )}
      </span>

      <Card.Body>
        {!hasRegisteredStudents ? (
          <Alert type="info" className="text-center">
            <strong>No Participants</strong>
          </Alert>
        ) : (
          <ul className="list-unstyled list-separated">
            {event.registered_students.slice(0, 10).map((registeredStudent) => (
              <RegisteredStudentsListItem
                key={registeredStudent.id}
                registeredStudent={registeredStudent}
              />
            ))}
          </ul>
        )}
      </Card.Body>
    </Dimmer>
  )
}

export default EventRegistrationStatusCount
