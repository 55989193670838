export enum EUserFilterOptions {
  all = 'all',
  disabled = 'disabled',
  enabled = 'enabled',
  fans = 'fans',
  following = 'following',
  mycru = 'mycru',
  positive = 'positive',
  negative = 'negative',
  zero = 'zero'
}

export enum EEventTypes {
  camp = '1',
  competition = '2',
  boogie = '3',
  course = '4',
  event = '5',
  workshop = '6',
  record = '7',
  invitational = '8',
  tournmament = '9',
  fightNight = '10'
}
