import React from 'react'
import Modal from '../../Modal'
import ChangePasswordForm from '../Forms/ChangePasswordForm'

interface IUserPasswordChangeModalProps {
  isModalOpen: boolean
  toggleModal: () => void
  user?: any
}

const UserPasswordChangeModal = ({
  isModalOpen,
  toggleModal,
  user
}: IUserPasswordChangeModalProps) => {
  return (
    <Modal
      content={
        <>
          <ChangePasswordForm
            isModal={true}
            toggleModal={toggleModal}
            user={user}
          />
        </>
      }
      open={isModalOpen}
      title={'Update Password'}
      onClose={toggleModal}
    />
  )
}

export default UserPasswordChangeModal
