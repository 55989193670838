import { useLazyQuery, useMutation } from '@apollo/client'
import { EHostnames } from 'components/constants'
import FormField from 'components/FormField'
import { UserStoreContext } from 'contexts/userStoreContext'
import { Formik, FormikValues } from 'formik'
import { ADD_STUDENT } from 'graphql/ADD_STUDENT'
import { useContext } from 'react'
import PhoneInput from 'react-phone-input-2'
import { toast } from 'react-toastify'
import { Button, Card, Form, Text } from 'tabler-react'
import { capitalizeName } from 'utils/stringFormat'
import * as Yup from 'yup'
import { GET_COACH_EMAIL_VALIDATION } from '../../../graphql/GET_COACH_EMAIL_VALIDATION'
import { GET_COACH_PHONE_VALIDATION } from '../../../graphql/GET_COACH_PHONE_VALIDATION'
import { GET_EMAIL_VALIDATION } from '../../../graphql/GET_EMAIL_VALIDATION'
import { GET_PHONE_VALIDATION } from '../../../graphql/GET_PHONE_VALIDATION'
import { useRootStore } from '../../../hooks'

const addStudentValidationSchema = Yup.object().shape({
  first_name: Yup.string().required(' '),
  last_name: Yup.string().required(' '),
  email: Yup.string().email().required(' '),
  phone_number: Yup.string().required(
    'Phone is required, including country code'
  )
})

interface ISignupFormProps {
  setStep: any
}

const SignUpForm = ({ setStep }: ISignupFormProps) => {
  const userStore = useContext(UserStoreContext)
  const { domainData } = useRootStore()
  const [validateEmail] = useLazyQuery(GET_EMAIL_VALIDATION, {
    fetchPolicy: 'network-only'
  })
  const [validatePhone] = useLazyQuery(GET_PHONE_VALIDATION, {
    fetchPolicy: 'network-only'
  })
  const [validateCoachEmail] = useLazyQuery(GET_COACH_EMAIL_VALIDATION, {
    fetchPolicy: 'network-only'
  })
  const [validateCoachPhone] = useLazyQuery(GET_COACH_PHONE_VALIDATION, {
    fetchPolicy: 'network-only'
  })

  const [addStudent] = useMutation(ADD_STUDENT)

  const renderForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched
  }: FormikValues) => {
    return (
      <form
        className="card Create-Account"
        method="POST"
        onSubmit={handleSubmit}
      >
        <Card.Body className="p-6">
          <FormField
            type="text"
            label="First Name*"
            placeholder="First name"
            name="first_name"
            onChange={(e) =>
              setFieldValue('first_name', capitalizeName(e.target.value))
            }
            value={values.first_name}
          />
          <FormField
            type="text"
            label="Last Name*"
            placeholder="Last name"
            name="last_name"
            onChange={(e) =>
              setFieldValue('last_name', capitalizeName(e.target.value))
            }
            value={values.last_name}
          />
          <div className={'form-group'}>
            <label className="form-label">Phone*</label>
            <PhoneInput
              inputProps={{
                className: 'form-control w-100',
                name: 'phone_number',
                required: true,

                onChange: (e) => {
                  e.preventDefault()
                  const target = e.target
                  const phone = target.value.split(' ')
                  const countryCode = phone.shift().replace(/\D/g, '')
                  const phoneNumber = phone.join('').replace(/\D/g, '')
                  setFieldValue('phone_number', phoneNumber)
                  setFieldValue('phone_country_code', Number(countryCode))
                }
              }}
              value={`${values.phone_country_code}${values.phone_number}`}
            />
            <span className="field-error text-danger">
              {errors.phone_number &&
                touched.phone_number &&
                errors.phone_number}
            </span>
          </div>

          <FormField
            appendleft={
              <Button icon={'mail'} color={'secondary text-muted'} disabled />
            }
            type="email"
            label="Email*"
            placeholder="Email"
            name="email"
            onChange={(e) =>
              setFieldValue('email', e.target.value.toLowerCase())
            }
            value={values.email}
          />

          <Form.Group className="text-center">
            {values.terms ? (
              <Button
                icon={'check-square'}
                color={'white'}
                size={'sm'}
                type={'button'}
                className={'text-secondary'}
                onClick={() => setFieldValue('terms', false)}
              >
                I AGREE
              </Button>
            ) : (
              <Button
                icon={'square'}
                color={'white'}
                size={'sm'}
                type={'button'}
                className={'text-muted'}
                onClick={() => setFieldValue('terms', true)}
              >
                I AGREE
              </Button>
            )}
            {domainData?.key === EHostnames.SKYCRU && (
              <Text.Small className="ml-2 d-block">
                <a
                  href={'https://skycru.com/privacy'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy and Terms of Use
                </a>
              </Text.Small>
            )}
            {domainData?.key === EHostnames.FAIRFIGHTS && (
              <Text.Small className="ml-2 d-block">
                <a
                  href={'https://fairfights.ca/privacy'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{' '}
                and
                <a
                  href={'https://fairfights.ca/terms'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  Terms of Use
                </a>
              </Text.Small>
            )}
            <span className="field-error text-danger">
              {errors.terms && touched.terms && errors.terms}
            </span>
          </Form.Group>
          <Form.Footer className="mb-3">
            <Button
              block
              icon="user"
              type="submit"
              className={`btn btn-primary btn-block ${
                isSubmitting && 'btn-loading'
              }`}
              disabled={isSubmitting}
            >
              CREATE ACCOUNT
            </Button>
          </Form.Footer>
        </Card.Body>
      </form>
    )
  }

  return (
    <>
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          phone_country_code: '1',
          terms: false
        }}
        validationSchema={addStudentValidationSchema}
        onSubmit={async (
          values,
          { setSubmitting, resetForm, setFieldError }
        ) => {
          const { terms, ...cloneStudent } = values
          let errorFound = false

          const emailData = await validateEmail({
            variables: { email: cloneStudent.email }
          }).then((res) => {
            return res.data
          })
          const coachEmail = await validateCoachEmail({
            variables: { email: values.email }
          }).then((res) => {
            return res.data
          })
          const phoneData = await validatePhone({
            variables: {
              phone: `${cloneStudent.phone_country_code}${cloneStudent.phone_number}`
            }
          }).then((res) => {
            return res.data
          })
          const coachPhone = await validateCoachPhone({
            variables: {
              phone: `${values.phone_country_code}${values.phone_number}`
            }
          }).then((res) => {
            return res.data
          })

          if (emailData?.validateEmail || coachEmail?.validateCoachEmail) {
            setFieldError('email', 'Email already exists. Contact SUPPORT.')
            errorFound = true
          }

          if (phoneData?.validatePhone || coachPhone?.validateCoachPhone) {
            setFieldError(
              'phone_number',
              'Phone number already exists. Contact SUPPORT.'
            )
            errorFound = true
          }

          if (!terms) {
            setFieldError('terms', 'Please read and agree to our Terms of Use!')
            errorFound = true
          }

          if (errorFound) {
            setSubmitting(false)
            return false
          }
          const newStudent = await addStudent({
            variables: {
              student: {
                ...cloneStudent
              }
            }
          })

          if (newStudent?.data.addStudent.id) {
            userStore.loadUser(newStudent?.data.addStudent)

            toast.success(
              'Please check your email. A temporary password will be sent to activate your account!'
            )
            setStep(3)
          } else {
            toast.error('Error creating account. Please contact support.')
          }
          setSubmitting(false)
          resetForm()
        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
      <Text className="text-center text-muted">
        Already have an account? <a href="/login">LOGIN</a>
      </Text>
    </>
  )
}

export default SignUpForm
