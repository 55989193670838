import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  defaultDataIdFromObject,
  from
} from '@apollo/client'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { ErrorBoundary, LEVEL_WARN, Provider } from '@rollbar/react'
import { RootStoreProvider } from 'contexts'
import 'mobx-react/batchingForReactDom'
import useGetNodeEnvironment, {
  ENodeEnvironment
} from 'modules/common/hooks/useGetNodeEnvironment'
import 'quill/dist/quill.snow.css'
import ReactDOM from 'react-dom'
import 'react-loading-skeleton/dist/skeleton.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'tabler-react/dist/Tabler.css'
import { API, getAPI } from './api'
import { App } from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import './styles/index.sass'
import { getEnvironmentFromHostname } from './utils/misc'

toast.configure({
  autoClose: 4000,
  position: 'bottom-left'
})

export const VERSION = process.env.REACT_APP_BUILD_VERSION
console.log('version', VERSION)

const nodeEnv = useGetNodeEnvironment()
const isProduction = nodeEnv === ENodeEnvironment.PRODUCTION
const environment = getEnvironmentFromHostname(
  window.location.hostname ?? 'unknown'
)

if (!isProduction) {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const rollbarConfig = {
  accessToken: '97905ab3f28944299c879ee11f0bd414',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment
}

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'SportTypes':
      case 'CoachTeam':
      case 'Location':
      case 'TeamCoach': {
        return
      }
      default: {
        return defaultDataIdFromObject(object) // fall back to default handling
      }
    }
  }
})

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log(graphQLErrors)
    if (Symbol.iterator in Object(graphQLErrors)) {
      for (const { message, locations, path } of graphQLErrors) {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      }
    }
  }
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const httpLink = new BatchHttpLink({
  uri: '/graphql',
  batchMax: 10, // No more than 5 operations per batch
  batchInterval: 20 // Wait no more than 20ms after first batched operation
})

const client = new ApolloClient({
  cache,
  link: from([errorLink, httpLink])
})

const api: API = getAPI(client)

const Root = () => {
  if (isProduction) {
    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary level={LEVEL_WARN}>
          <App />
        </ErrorBoundary>
      </Provider>
    )
  } else {
    return <App />
  }
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <RootStoreProvider api={api}>
      <Root />
    </RootStoreProvider>
  </ApolloProvider>,

  document.querySelector('#root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
