import { useMutation, useQuery } from '@apollo/client'
import { manualPaymentOpts } from 'components/constants'
import DatePicker from 'components/DatePicker'
import { DELETE_PAYMENT } from 'graphql/DELETE_PAYMENT'
import { GET_PAYMENTS } from 'graphql/GET_PAYMENTS'
import { REFUND_PAYMENT } from 'graphql/REFUND_PAYMENT'
import { GET_PAYMENTS as GET_PAYMENTS_TYPE } from 'graphql/types/GET_PAYMENTS'
import { useRootStore } from 'hooks/useRootStore'
import { observer } from 'mobx-react'
import ViewPaymentBtn, {
  ViewPaymentModal
} from 'modules/payment/components/buttons/ViewPaymentBtn'
import { EPaymentStatus } from 'modules/payment/constants/paymentStatus'
import PaymentListHeader from 'modules/payment/lists/PaymentListHeader'
import DeletePaymentModal from 'modules/payment/modals/DeletePaymentModal'
import RefundPaymentModal from 'modules/payment/modals/RefundPaymentModal'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { CSVDownload } from 'react-csv'
import { toast } from 'react-toastify'
import {
  Alert,
  Button,
  Card,
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Icon,
  Table
} from 'tabler-react'
import { formatMoney } from 'utils/numberFormat'
import StudentLink from '../../../components/Students/StudentLink'
import { UPDATE_PAYMENT_STATUS } from '../../../graphql/UPDATE_PAYMENT_STATUS'
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType'
import { Types } from '../../../types/graphql'
import { UseGetPayments } from '../hooks/UseGetPayments'
import UpdatePayementModal from '../modals/UpdatePaymentModal'

const PaymentsListItem = ({ payment }: { payment: Types.Payments }) => {
  const [isDeletePaymentModalOpen, setisDeletePaymentModalOpen] =
    useState(false)
  const [isUpdatePaymentStatusModalOpen, setIsUpdatePaymentStatusModalOpen] =
    useState(false)
  const [isStripeRefundModalOpen, setIsStripeRefundModalOpen] = useState(false)
  const [paymentId, setPaymentId] = useState<string | null>(null)
  const [status, setStatus] = useState<string>('')
  const { isAdminGroup, isAdmin } = useGetCurrentUserType()

  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false)

  const toggleReceiptModal = () => {
    setIsReceiptModalOpen(!isReceiptModalOpen)
  }

  const toggleDeletePaymentModal = () => {
    setisDeletePaymentModalOpen(!isDeletePaymentModalOpen)
  }

  const toggleUpdatePaymentStatusModal = () => {
    setIsUpdatePaymentStatusModalOpen(!isUpdatePaymentStatusModalOpen)
  }

  const toggleStripeRefundModal = () => {
    setIsStripeRefundModalOpen(!isStripeRefundModalOpen)
  }

  const [deletePaymentMutation] = useMutation(DELETE_PAYMENT, {
    onCompleted: () => {
      toggleDeletePaymentModal()
      toast.success('Payment Deleted.')
    },
    refetchQueries: ['GET_PAYMENTS']
  })

  const [updatePaymentStatus] = useMutation(UPDATE_PAYMENT_STATUS, {
    onCompleted: () => {
      toggleUpdatePaymentStatusModal()
      toast.success(`Payment ${status}.`)
    },
    refetchQueries: ['GET_PAYMENTS']
  })

  const [refundPaymentMutation] = useMutation(REFUND_PAYMENT, {
    onCompleted: () => {
      toggleStripeRefundModal()
      toast.success('Payment Refunded.')
    },
    refetchQueries: ['GET_PAYMENTS']
  })

  const onDeletePayment = async () => {
    await deletePaymentMutation({
      variables: {
        payment_id: Number(paymentId)
      }
    })
  }

  const onUpdatePaymentStatus = async () => {
    await updatePaymentStatus({
      variables: {
        payment_id: Number(paymentId),
        status
      }
    })
  }

  const onRefundPayment = async () => {
    await refundPaymentMutation({
      variables: {
        payment_id: Number(paymentId)
      }
    })
  }

  return (
    <Table.Row>
      {isAdmin && <Table.Col>{payment.id}</Table.Col>}
      <Table.Col><strong>
        <StudentLink
          studentName={`${payment?.first_name ?? ''} ${
            payment?.last_name ?? ''
          }`}
          studentId={Number(payment?.student_id)}
        /></strong>
      </Table.Col>
      <Table.Col>
        {payment?.camp_name && `${payment.camp_name}`}
        {payment?.product_title && payment.product_title}
        {payment?.invoice_id && `INV: ${payment.invoice_id}`}
      </Table.Col>
      <Table.Col>
        <Button
          icon={payment.last4 ? 'credit-card' : ''}
          color={payment.last4 ? 'white' : 'white'}
          size="sm"
          className={payment.last4 ? 'text-primary' : ''}
        >
          <span className="text-uppercase">{payment?.card_brand}</span>
          {payment.last4 && (
            <span className="ml-2">{' x' + payment.last4}</span>
          )}
          {payment?.card_country && ` (${payment.card_country})`}
          {payment?.manual_payment}
        </Button>
      </Table.Col>
      <Table.Col>
        {moment(payment.created_on).locale(navigator.language).format('ll LT')}
      </Table.Col>
      <Table.Col>
        {formatMoney(payment.amount)}{' '}
        <span className="text-muted">
          {isAdmin && payment.currency?.toUpperCase()}
        </span>
      </Table.Col>
      <Table.Col>
        <Button
          icon={payment.status === EPaymentStatus.paid ? 'check-circle' : ''}
          size={'sm'}
          color={'white'}
          className={
            payment.status === EPaymentStatus.paid
              ? 'text-success'
              : 'text-danger'
          }
        >
          {payment.status.toUpperCase()}
        </Button>
      </Table.Col>
      <Table.Col>
        <Dropdown
          arrow
          arrowPosition="right"
          triggerContent={<Icon name={'more-vertical'} />}
          position="bottom-end"
          toggle={false}
          items={
            <>
              {payment.processed_by === 'Manual Payment' && (
                <>
                  {(isAdminGroup) && (
                    <Dropdown.Item
                      icon="x-circle"
                      className="cursor-pointer text-danger"
                      onClick={(e) => {
                        e.preventDefault()
                        setPaymentId(payment.id)
                        toggleDeletePaymentModal()
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    icon="minus-circle"
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault()
                      if (payment.status === 'Void') return
                      setPaymentId(payment.id)
                      setStatus('Void')
                      toggleUpdatePaymentStatusModal()
                    }}
                  >
                    Void
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon="x-circle"
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault()
                      if (payment.status === 'Refund') return
                      setPaymentId(payment.id)
                      setStatus('Refund')
                      toggleUpdatePaymentStatusModal()
                    }}
                  >
                    Refund
                  </Dropdown.Item>
                </>
              )}
              {payment.processed_by === 'Stripe' &&
                payment.status !== 'Refunded' && (
                  <Dropdown.Item
                    icon="x-circle"
                    className="cursor-pointer"
                    onClick={() => {
                      setPaymentId(payment.id)
                      toggleStripeRefundModal()
                    }}
                  >
                    Refund
                  </Dropdown.Item>
                )}
              <ViewPaymentBtn toggleReceiptModal={toggleReceiptModal} />
            </>
          }
        />
        <ViewPaymentModal
          payment_id={Number(payment.id)}
          toggleReceiptModal={toggleReceiptModal}
          isReceiptModalOpen={isReceiptModalOpen}
        />
      </Table.Col>
      <DeletePaymentModal
        isModalOpen={isDeletePaymentModalOpen}
        onConfirm={onDeletePayment}
        toggleModal={toggleDeletePaymentModal}
      />
      <UpdatePayementModal
        isModalOpen={isUpdatePaymentStatusModalOpen}
        onConfirm={onUpdatePaymentStatus}
        toggleModal={toggleUpdatePaymentStatusModal}
        status={status}
      />
      <RefundPaymentModal
        isModalOpen={isStripeRefundModalOpen}
        onConfirm={onRefundPayment}
        toggleModal={toggleStripeRefundModal}
      />
    </Table.Row>
  )
}

const PaymentsPageCSV = ({ dateFilter, manualPayment, cardBrand }) => {
  const { data, loading, loadData } = UseGetPayments({
    dateFilter,
    manualPayment,
    cardBrand
  })
  let payments = []
  if (data?.getPayments && !loading) {
    payments =
      data?.getPayments.payments.map(
        ({
          id,
          amount,
          application_fee,
          camp_name,
          card_brand,
          card_country,
          coach_first_name,
          coach_last_name,
          created_on,
          currency,
          description,
          fees,
          first_name,
          invoice_id,
          last_name,
          last4,
          manual_payment,
          processed_by,
          product_title,
          status,
          team_name,
          total
        }) => ({
          customer: `${first_name} ${last_name}`,
          submittedBy: coach_first_name
            ? `${coach_first_name} ${coach_last_name}`
            : 'Customer',
          teamName: team_name,
          paymentDate: moment(created_on).format('DD MMM YYYY h:mm a'),
          paymentMethod: manual_payment,
          description: description,
          processedBy: processed_by,
          cardBrand: card_brand,
          last4,
          cardCountry: card_country,
          total,
          applicatoinFee: application_fee,
          customerFee: fees,
          chargeAmount: amount,
          paymentId: id
        })
      ) ?? []
  }

  if (loading) {
    return (
      <Button loading color="secondary">
        Processing...
      </Button>
    )
  }

  return (
    <>
      {payments.length > 0 && (
        <CSVDownload data={payments} filename="payments.csv" />
      )}
      <Button
        outline
        icon="download"
        color="secondary"
        size="sm"
        className={'w-25'}
        onClick={loadData}
        disabled={loading}
      >
        CSV
      </Button>
    </>
  )
}

const PaymentsList = () => {
  const { currentCoachTeam } = useRootStore()
  const { isAdminGroup, isAdmin } = useGetCurrentUserType()
  const [dateFilter, setDateFilter] = useState(new Date())
  const [manualPayment, setManualPayment] = useState('')
  const [cardBrand, setCardBrand] = useState('')

  const { loading, data } = useQuery<GET_PAYMENTS_TYPE>(GET_PAYMENTS, {
    variables: {
      params: {
        team_id: currentCoachTeam?.id,
        date_filter: dateFilter,
        manual_payment: manualPayment,
        card_brand: cardBrand
      }
    }
  })

  const payments = useMemo(
    () => data?.getPayments.payments ?? [],
    [data?.getPayments, loading]
  )

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (
      e.target.value === 'American Express' ||
      e.target.value === 'MasterCard' ||
      e.target.value === 'Visa'
    ) {
      setCardBrand(e.target.value)
      setManualPayment(null)
    } else {
      setManualPayment(e.target.value)
      setCardBrand(null)
    }
  }

  return (
    <div className="container">
      <Dimmer active={loading} loader={loading}>
        <Grid.Row>
          <PaymentListHeader
            total={data?.getPayments.total ?? 0}
            totalComp={data?.getPayments.totalComp ?? 0}
            totalPaid={data?.getPayments.totalPaid ?? 0}
          />
        </Grid.Row>

        <Grid.Row>
          <Grid.Col>
            <Card>
              <Card.Header>
                <Card.Title>
                  <Icon name="credit-card" className="mr-2 ml-0 text-success" />
                  Payments
                </Card.Title>
                <Card.Options className="gap-2">
                  <Button
                    className="text-secondary"
                    color="white"
                    icon="chevron-left"
                    size="sm"
                    onClick={() => {
                      setDateFilter(
                        moment(dateFilter).subtract(1, 'months').toDate()
                      )
                    }}
                  />
                  <DatePicker
                    name="created_on"
                    placeholder="Select Date"
                    onChange={(date) => setDateFilter(date)}
                    value={dateFilter}
                    views={['year', 'month']}
                    format="MMM yyyy"
                    className="w-50"
                  />

                  <Form.Select
                    name="payment_method"
                    className="w-50"
                    onChange={handleChange}
                  >
                    <option value="">All</option>
                    {manualPaymentOpts
                      .concat(
                        {
                          value: 'American Express',
                          label: 'AMEX'
                        },
                        { value: 'MasterCard', label: 'MasterCard' },
                        { value: 'Visa', label: 'VISA' }
                      )
                      .map((paymentType) => (
                        <option
                          value={paymentType.value}
                          key={paymentType.value}
                        >
                          {paymentType.label}
                        </option>
                      ))}
                  </Form.Select>
                  {isAdmin && (
                    <Form.Select
                      name="currency"
                      className="w-50"
                      onChange={handleChange}
                      disabled={!isAdmin}
                    >
                      <option value="">USD</option>
                      <option value="">EUR</option>
                      <option value="">CAD</option>
                    </Form.Select>
                  )}
                  {isAdminGroup && (
                    <PaymentsPageCSV
                      dateFilter={dateFilter}
                      manualPayment={manualPayment}
                      cardBrand={cardBrand}
                    />
                  )}
                </Card.Options>
              </Card.Header>
              <Card.Body>
                <Table className="card-table">
                  <Table.Header>
                    <Table.Row>
                      {isAdmin && <Table.ColHeader>ID</Table.ColHeader>}
                      <Table.ColHeader>Customer</Table.ColHeader>
                      <Table.ColHeader>Description</Table.ColHeader>
                      <Table.ColHeader>Method</Table.ColHeader>
                      <Table.ColHeader>Created</Table.ColHeader>
                      <Table.ColHeader>Amount</Table.ColHeader>
                      <Table.ColHeader>Status</Table.ColHeader>
                      <Table.ColHeader></Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {payments.length > 0 ? (
                      payments.map((payment) => (
                        <PaymentsListItem key={payment.id} payment={payment} />
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Col colSpan="12">
                          <Alert type="info text-center">
                            <strong>No Data</strong>
                          </Alert>
                        </Table.Col>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Dimmer>
    </div>
  )
}

export default observer(PaymentsList)
