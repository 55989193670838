import { STATUS_ICON_COLOR_MAPPING } from 'modules/user/registrations/constants/registrationStatusMapping'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Button, Dropdown, Grid, Text } from 'tabler-react'
import useReactRouter from 'use-react-router'
import { UserStoreContext } from '../../contexts/userStoreContext'
import { Types } from '../../types/graphql'

interface INewRegistrationsListItemProps {
  student: Types.RegisteredStudents
}

const MAX_TEXT_LENGTH = 20

const RecentStudentRegistrationsListItem = ({
  student
}: INewRegistrationsListItemProps) => {
  const userStore = useContext(UserStoreContext)
  const { history } = useReactRouter()
  const routeToStudent = (student: Types.RegisteredStudents) => {
    userStore.loadUser(student)
    history.push('/user-details')
  }
  const fullName = student.first_name + ' ' + student.last_name

  return (
    <li className="list-separated-item">
      <Grid.Row className="align-items-center">
        <Grid.Col auto>
          <Link
            className="text-inherit"
            to={`/events/registrations/${student.user_registrations_id}/${student.student_id}`}
          >
            <Avatar
              status={
                STATUS_ICON_COLOR_MAPPING.get(student.status)?.color ?? 'danger'
              }
              size="md"
              className="d-block"
              imageURL={
                (student.profile_avatar &&
                  `/api/s3/uploads/${student.profile_avatar}`) ||
                '//www.gravatar.com/avatar?d=mp'
              }
            />
          </Link>
        </Grid.Col>
        <Grid.Col>
          <Link
            className="text-inherit"
            to={`/events/registrations/${student.user_registrations_id}/${student.student_id}`}
          >
            {fullName}
            <Button
              icon={STATUS_ICON_COLOR_MAPPING.get(student.status)?.icon ?? 'x'}
              color="white"
              className={
                'float-right ' +
                  STATUS_ICON_COLOR_MAPPING.get(student.status)?.className ??
                'text-danger'
              }
            />
          </Link>
          <Text.Small muted className="d-block">
            <Link to={`/user/calendar/${student.camp_id}`}>
              {student.camp_name.length > MAX_TEXT_LENGTH ? (
                <>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: student.camp_name.substring(0, MAX_TEXT_LENGTH)
                    }}
                  />
                  ...
                </>
              ) : (
                student.camp_name
              )}
            </Link>
          </Text.Small>
        </Grid.Col>
        <Grid.Col auto>
          <Dropdown
            trigger={<Dropdown.Trigger icon="more-vertical" toggle={false} />}
            position="right"
            items={
              <>
                <Dropdown.Item
                  icon="user"
                  to={`/events/registrations/${student.user_registrations_id}/${student.student_id}`}
                >
                  View Registration
                </Dropdown.Item>
                <Dropdown.Item
                  icon="user"
                  to="#!"
                  onClick={(e: { preventDefault: () => void }) => {
                    e.preventDefault()
                    routeToStudent(student)
                  }}
                >
                  View User
                </Dropdown.Item>
                <Dropdown.Item icon="mail" to={`mailto:${student.email}`}>
                  {student.email}
                </Dropdown.Item>
              </>
            }
          />
        </Grid.Col>
      </Grid.Row>
    </li>
  )
}

export default RecentStudentRegistrationsListItem
