import { observer } from 'mobx-react'
import React, { useContext, useState } from 'react'
import { Alert, Grid, Text } from 'tabler-react'

import { UserStoreContext } from '../../contexts/userStoreContext'
import { useRootStore } from '../../hooks'
import { Types } from '../../types/graphql'
import { EUserTypes } from '../User/userTypes'

import GearCardItem from './GearCardItem'
import GearCardModalForm from './Modal/GearCardModalForm'
import GearJobModalForm from './Modal/GearJobModalForm'

interface IGearCardProps {
  userGears: Types.UserGear[]
  search?: string
}

const GearCard = ({ userGears, search }: IGearCardProps) => {
  const userStore = useContext(UserStoreContext)
  const rootStore = useRootStore()
  const { currentUser } = rootStore
  const { user } = userStore

  const studentId =
    currentUser?.type === EUserTypes.student ? currentUser?.id : user?.id

  // Modal controls
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isJobModalOpen, setIsJobModalOpen] = useState<boolean>(false)

  // UserGear states

  const [userGear, setUserGear] = useState<Types.UserGear>(null)

  // UserJob states
  const [userGearJob, setUserGearJob] = useState(null)

  const handleToggleModal = (isOpen) => {
    setIsModalOpen(isOpen)
  }

  const handleJobToggleModal = (userGearId) => {
    setUserGearJob({
      user_gear_id: userGearId,
      student_id: studentId,
      student: { first_name: user.first_name, last_name: user.last_name }
    })
    setIsJobModalOpen(!isJobModalOpen)
  }

  return (
    <>
      <GearCardModalForm
        userGear={userGear}
        toggleModal={handleToggleModal}
        isModalOpen={isModalOpen}
        studentId={Number(studentId)}
      />
      {[EUserTypes.rigger.toString()].includes(currentUser?.type) &&
        userGearJob && (
          <GearJobModalForm
            userGearJob={userGearJob}
            toggleModal={handleJobToggleModal}
            isModalOpen={isJobModalOpen}
          />
        )}

      <Grid.Row>
        {userGears.length > 0 ? (
          userGears
            .filter((g) => {
              if (!search) return true
              const rigName = g?.rig ? g?.rig.name : ''
              return (
                g.gear_serial.toLowerCase().indexOf(search) !== -1 ||
                g?.name.toLowerCase().indexOf(search) !== -1 ||
                rigName.toLowerCase().indexOf(search) !== -1
              )
            })
            .map((userGear) => (
              <Grid.Col xl={4} lg={6} md={6} sm={12} xs={12} key={userGear.id}>
                <GearCardItem
                  userGear={userGear}
                  toggleModal={handleToggleModal}
                  toggleJobModal={handleJobToggleModal}
                  setUserGear={setUserGear}
                  jumpWeight={user.jump_weight}
                  weight={user.weight}
                />
              </Grid.Col>
            ))
        ) : (
          <Grid.Col>
            <Alert type="info text-center">
              <strong>Your Gear Bag is empty!</strong>
            </Alert>
            <Text className="mt-5 text-center text-muted">
              You must add gear before creating a rig.
            </Text>
          </Grid.Col>
        )}
      </Grid.Row>
    </>
  )
}

export default observer(GearCard)
