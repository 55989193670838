import { useMutation } from '@apollo/client';
import { TeamStoreContext } from 'contexts/teamStoreContext';
import { UPDATE_TEAM_BY_ID } from 'graphql/UPDATE_TEAM_BY_ID';
import { observer } from 'mobx-react';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';
import { COACH_ACCOUNT_TYPES } from 'modules/team/constants/coachAccountTypes';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown } from 'tabler-react';

type Props = {
  teamId: number;
};

const CoachAccountType = ({ teamId }: Props) => {
  const { teams } = useContext(TeamStoreContext);
  const { isAdmin } = useGetCurrentUserType();

  const team = teams.find((team) => team.id === String(teamId));

  const coachAccountType =
    COACH_ACCOUNT_TYPES[team?.coach_business_type] ?? 'Undefined';

  const [updateTeamById] = useMutation(UPDATE_TEAM_BY_ID);

  const onCoachAccountTypeChange = async (key: string) => {
    await updateTeamById({
      variables: {
        team: {
          id: teamId,
          coach_business_type: key
        }
      },
      refetchQueries: ['GET_TEAMS'],
      update(_, { data: { updateTeamById } }) {
        if (updateTeamById) {
          team.set('coach_business_type', key);
        }
      }
    });

    toast.success('Coach account type updated successfully');
  };

  if (team?.coach_business_type) {
    return (
      <Button
        color="white"
        size="sm"
        className="text-muted"
        disabled={!isAdmin}
      >
        {coachAccountType} Account
      </Button>
    );
  }

  return (
    <Dropdown
      triggerClassName="text-muted"
      triggerContent={`${coachAccountType} Account`}
      isNavLink={true}
      type="button"
      size="sm"
      items={
        <>
          {Object.keys(COACH_ACCOUNT_TYPES).map((key) => (
            <Dropdown.Item
              key={key}
              onClick={(e) => {
                e.preventDefault();
                onCoachAccountTypeChange(key);
              }}
            >
              {COACH_ACCOUNT_TYPES[key]}
            </Dropdown.Item>
          ))}
        </>
      }
    />
  );
};

export default observer(CoachAccountType);
