import { useQuery } from '@apollo/client'
import { EHostnames } from 'components/constants'
import { UserStoreContext } from 'contexts/userStoreContext'
import { GET_STUDENTS_BY_FILTER_QUERY } from 'graphql/GET_STUDENTS_BY_FILTER_QUERY'
import { useRootStore } from 'hooks/useRootStore'
import { observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import useGetNodeEnvironment from 'modules/common/hooks/useGetNodeEnvironment'
import HeaderNavItems from 'pages/layout/HeaderNavItems'
import React, { FC, useContext, useEffect, useMemo } from 'react'
import { Avatar, Button, Container, Dropdown, Nav, Text } from 'tabler-react'
import useReactRouter from 'use-react-router'
import AddStudentCoachModal from '../../components/Students/Modal/AddStudentCoachModal'
import SiteLogo from './SiteLogo'

interface HeaderProps {
  collapse: boolean
  setCollapse: (collapse: boolean) => void
}

type TeamsArray =
  | {
      value: string
      onClick: () => void
    }
  | { isDivider?: boolean }

const Header: FC<HeaderProps> = ({ collapse, setCollapse }) => {
  const { history } = useReactRouter()
  const rootStore = useRootStore()
  const { isAdmin, isCoachAdmin, isCoachSuperAdmin, isStudent } =
    useGetCurrentUserType()
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] =
    React.useState(false)
  const handleToggleAddStudentModal = () =>
    setIsAddStudentModalOpen(!isAddStudentModalOpen)

  const { currentCoachTeam, currentUser, domainData } = rootStore

  const nodeEnv = useGetNodeEnvironment()

  const teamsDropdownItems = useMemo(() => {
    const teamsArr: TeamsArray[] = rootStore.coachTeams.map((coachTeam) => {
      return {
        value: coachTeam.name,
        onClick: () => {
          applySnapshot(rootStore, {
            ...rootStore,
            currentCoachTeam: { ...coachTeam },
            currentUser: {
              ...currentUser,
              type: coachTeam?.coach_business_type ?? currentUser?.type
            }
          })
          history.push('/')
        }
      }
    })

    teamsArr.push({ isDivider: true })

    if (rootStore.currentCoachTeam?.slug) {
      teamsArr.push({
        value: 'Public Page',
        onClick: () => {
          history.push(`/${rootStore.currentCoachTeam?.slug}`)
        }
      })
    }

    //  if (isCoachAdmin || isCoachSuperAdmin) {
    teamsArr.push({
      value: 'Settings',
      onClick: () => {
        history.push(`/team-details/${rootStore.currentCoachTeam?.id}`)
      }
    })
    //  }
    return teamsArr
  }, [rootStore.coachTeams, rootStore.currentCoachTeam, currentUser])

  const userStore = useContext(UserStoreContext)

  const { loading, error, data } = useQuery(GET_STUDENTS_BY_FILTER_QUERY, {
    variables: {
      filter: {
        limit: 1,
        student_id: currentUser.id.toString()
      }
    }
  })

  // Set filtered students everytime data changes
  useEffect(() => {
    if (!loading && !error && data) {
      if (currentUser.type === 'student') {
        userStore.loadUser(data[Object.keys(data)[0]][0])
      }
    }
  }, [data])

  const { first_name, last_name, email, profile_avatar } = currentUser

  return (
    <div className="header py-4">
      <Container>
        <AddStudentCoachModal
          isOpen={isAddStudentModalOpen}
          handleClose={handleToggleAddStudentModal}
        />
        <div className="d-flex justify-content-between">
          <span
            className="header-toggler d-lg-none ml-lg-0 cursor-pointer"
            onClick={() => setCollapse(!collapse)}
          >
            <span className="header-toggler-icon" />
          </span>
          <span
            className="header-brand cursor-pointer"
            onClick={() => history.push(`/`)}
          >
            {currentCoachTeam?.team_logo_header ? (
              <div
                className="header-team-logo-wide d-none d-lg-block"
                style={{
                  backgroundImage: `url(/api/s3/uploads/${currentCoachTeam?.team_logo_header})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  height: '40px',
                  width: '250px',
                  backgroundPosition: 'left'
                }}
              />
            ) : (
              <SiteLogo customClass={'d-none d-lg-block'} />
            )}
          </span>
          <FeatureAccessGate feature="feature_open_add_students">
            {(isCoachAdmin || isCoachSuperAdmin) && !isAdmin && (
              <span
                className="header-toggler d-lg-none ml-lg-0 cursor-pointer"
                onClick={handleToggleAddStudentModal}
              >
                <Button
                  icon="user-plus"
                  className="text-success"
                  color="white"
                  size="sm"
                />
              </span>
            )}
          </FeatureAccessGate>
          <div className="d-flex">
            {!currentUser && (
              <Button
                color="primary"
                size="sm"
                onClick={() => history.push(`/login`)}
              >
                Login
              </Button>
            )}
          </div>
          <div className="d-flex">
            {isAdmin && (
              <Button
                icon="activity"
                color="white"
                size="sm"
                onClick={() => history.push(`/admin/user-events`)}
              />
            )}
            {!currentCoachTeam && !isAdmin && !isStudent && (
              <Button
                color="white"
                size="sm"
                className="text-muted float-right"
                onClick={() => history.push(`/coach/pricing`)}
              >
                Limited Access
              </Button>
            )}
            {isStudent && domainData?.key === EHostnames.FAIRFIGHTS && (
            // !isFighter
              <Button
                color="white"
                size="sm"
                className="text-muted float-right"
                disabled
              >
                Fighter Profile
              </Button>
            )}
            <div className="dropdown d-flex">
              {!isAdmin && !isStudent && (
                <Dropdown
                  triggerContent={rootStore.currentCoachTeam?.name}
                  className="mt-1 text-secondary cursor-pointer"
                  position="bottom-end"
                  arrow={true}
                  arrowPosition="right"
                  itemsObject={teamsDropdownItems}
                />
              )}
              <Dropdown
                as={Nav.Item}
                link={false}
                triggerClassName="d-flex align-items-center py-0 px-lg-0 px-2 text-color ml-2 cursor-pointer"
                triggerAs={Nav.Link}
                triggerContent={
                  <React.Fragment>
                    <Avatar
                      size="md"
                      imageURL={
                        profile_avatar
                          ? `/api/s3/uploads/${profile_avatar}`
                          : '//www.gravatar.com/avatar?d=mp'
                      }
                    />
                    <span className="ml-2 d-none d-lg-block leading-none">
                      <Text>{`${first_name} ${last_name}`}</Text>
                      <Text.Small className="text-muted d-block mt-1">
                        {email}
                        {isCoachSuperAdmin && !isAdmin && (
                          <span className="ml-2 status-icon bg-success" />
                        )}
                      </Text.Small>
                    </span>
                  </React.Fragment>
                }
                position="bottom-end"
                arrow={true}
                arrowPosition="right"
                toggle={false}
                items={<HeaderNavItems />}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default observer(Header)
