import { useMutation } from '@apollo/client'
import StudentMeritsButton from 'components/Students/StudentMeritsButton'
import UserCardDropdown from 'components/User/UserCardDropdown'
import { EUserTypes } from 'components/User/userTypes'
import { UserStoreContext } from 'contexts/userStoreContext'
import { UPDATE_STUDENT_MUTATION } from 'graphql/UPDATE_STUDENT_MUTATION'
import { UPDATE_USER_MUTATION } from 'graphql/UPDATE_USER_MUTATION'
import { useRootStore } from 'hooks'
import { observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import {
  useGetUserIsAdmin,
  useGetUserIsCoach,
  useGetUserIsCoachSuperAdmin,
  useGetUserIsStudent
} from 'modules/common/hooks/useGetCurrentUserType'
import QRCodeStudent from 'modules/student/components/buttons/QRCodeStudent'
import { useContext, useMemo } from 'react'
import { toast } from 'react-toastify'
import {
  Avatar,
  Button,
  Card,
  Dropdown,
  Grid,
  Header,
  Text
} from 'tabler-react'
import useReactRouter from 'use-react-router'
import { Types } from '../../../types/graphql'

interface IUserCardProps {
  firstName?: string
  lastName?: string
  profileAvatar?: string
  profilePicture?: string
  refetch?: any
  studentId?: number
  goToStudentDetails?: boolean
  studentData?: Types.Student | Types.Student
  source?: string
}

const UserCard = ({
  firstName,
  lastName,
  profileAvatar,
  profilePicture,
  refetch,
  studentId,
  goToStudentDetails = false,
  studentData,
  source
}: IUserCardProps) => {
  const userStore = useContext(UserStoreContext)
  const { user } = userStore
  const rootStore = useRootStore()
  const { currentUser, coach, coaches } = rootStore
  const { id, type } = currentUser
  const typeOverride = source === 'admin' ? EUserTypes.coach : type
  const idOverride = source === 'admin' ? coach?.id : id

  const { history, location } = useReactRouter()
  const isStudent = useGetUserIsStudent()
  const isCoach = useGetUserIsCoach()
  const isAdmin = useGetUserIsAdmin()
  const isCoachSuperAdmin = useGetUserIsCoachSuperAdmin()

  const adminToUpdateStudent: boolean =
    isAdmin && location.pathname === '/user-details'

  const canUploadAvatar = useMemo(() => {
    if (
      (isAdmin || isCoachSuperAdmin) &&
      location.pathname === '/admin/coach-details'
    ) {
      return true
    }
    if (
      !isAdmin &&
      location.pathname !== '/user/profile' &&
      location.pathname === '/user/settings'
    ) {
      return true
    }

    if (!profileAvatar && location.pathname === '/user/profile') {
      return true
    }

    if (!profileAvatar && location.pathname === '/user-details') {
      return true
    }

    if (location.pathname === '/user/settings' && isStudent) {
      return true
    }

    if (location.pathname.includes('/user/calendar') && !isAdmin) {
      return true
    }

    return false
  }, [location.pathname, isAdmin, isStudent, profileAvatar])

  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION, {
    onCompleted: () => toast.success('Avatar Updated.')
  })

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => toast.success('Avatar Updated.')
  })

  const onError = (error) => {
    toast.error(error)
    rootStore.setLoading(false)
  }

  const onFinished = (response: { fileKey: string }) => {
    updateAvatar(response.fileKey)
    rootStore.setLoading(false)
  }

  const onFinishedBanner = (response: { fileKey: string }) => {
    updateBanner(response.fileKey)
    rootStore.setLoading(false)
  }

  const updateAvatar = async (fileKey: string | null) => {
    if (isCoach && location.pathname.includes('/user/calendar')) {
      await updateStudent({
        variables: {
          student: {
            id: studentId,
            profile_avatar: fileKey
          }
        }
      })
      if (location.pathname.includes('/user/calendar')) {
        refetch()
      }
    } else if (isStudent || adminToUpdateStudent) {
      await updateStudent({
        variables: {
          student: {
            id: adminToUpdateStudent ? user.id : id,
            profile_avatar: fileKey
          }
        }
      })
      if (location.pathname.includes('/user/calendar')) {
        refetch()
      }
    } else {
      await updateUser({
        variables: {
          user: {
            id: idOverride,
            profile_avatar: fileKey,
            type: typeOverride
          }
        }
      })
    }

    if (adminToUpdateStudent) {
      applySnapshot(user, {
        ...user,
        profile_avatar: fileKey
      })
    } else {
      if (source === 'admin') {
        rootStore.updateCoach({ ...coach, profile_avatar: fileKey })
      } else {
        applySnapshot(currentUser, {
          ...currentUser,
          profile_avatar: fileKey
        })
      }
    }
  }

  const updateBanner = async (fileKey: string | null) => {
    if (typeOverride === EUserTypes.student || adminToUpdateStudent) {
      await updateStudent({
        variables: {
          student: {
            id: adminToUpdateStudent ? user.id : idOverride,
            profile_picture: fileKey
          }
        }
      })
    } else {
      await updateUser({
        variables: {
          user: {
            id: idOverride,
            profile_picture: fileKey,
            type: typeOverride
          }
        }
      })
    }

    if (adminToUpdateStudent) {
      applySnapshot(user, {
        ...user,
        profile_picture: fileKey
      })
    } else {
      if (source === 'admin') {
        rootStore.updateCoach({ ...coach, profile_picture: fileKey })
      } else {
        applySnapshot(currentUser, {
          ...currentUser,
          profile_picture: fileKey
        })
      }
    }
  }

  const routeToStudent = () => {
    userStore.detach(user)
    userStore.loadUser(studentData)
    history.push('/user-details')
  }

  return (
    <>
      <Card className="card-profile">
        <Card.Header
          backgroundURL={
            (profilePicture && `/api/s3/uploads/${profilePicture}`) ||
            '//via.placeholder.com/374x144.png?text=+'
          }
        >
          <div>
            {user?.token &&
              isAdmin &&
              location.pathname === '/user-details' && (
                <QRCodeStudent
                  token={user.token}
                  id={user.id}
                  firstName={firstName}
                  lastName={lastName}
                  //  profilePicture={profilePicture}
                />
              )}
          </div>
          {canUploadAvatar && (
            <div className="banner-dropdown-container">
              <Dropdown
                className="cursor-pointer banner-dropdown"
                icon="camera"
                items={
                  <UserCardDropdown
                    rootStore={rootStore}
                    imageName={profilePicture}
                    onErrorHandler={onError}
                    onFinishedHandler={onFinishedBanner}
                    callBack={updateBanner}
                  />
                }
                toggle={false}
              />
            </div>
          )}
        </Card.Header>
        <Card.Body className="text-center">
          {
            // Only show options (Upload, Create, and Remove) on settings or current user type is admin
            canUploadAvatar ? (
              <Dropdown
                className="cursor-pointer avatar-dropdown"
                icon="camera"
                items={
                  <UserCardDropdown
                    rootStore={rootStore}
                    imageName={profileAvatar}
                    onErrorHandler={onError}
                    onFinishedHandler={onFinished}
                    callBack={updateAvatar}
                  />
                }
                toggle={false}
                triggerContent={
                  <Avatar
                    className="card-profile-img avatar"
                    imageURL={
                      (profileAvatar && `/api/s3/uploads/${profileAvatar}`) ||
                      '//www.gravatar.com/avatar?d=mp'
                    }
                    size="xxl"
                  />
                }
              />
            ) : (
              <Avatar
                className="card-profile-img avatar"
                imageURL={
                  (profileAvatar && `/api/s3/uploads/${profileAvatar}`) ||
                  '//www.gravatar.com/avatar?d=mp'
                }
                size="xxl"
              />
            )
          }

          {firstName && lastName && (
            <Header.H3 className="mb-3">{`${firstName} ${lastName}`}</Header.H3>
          )}

          {isAdmin && <StudentMeritsButton studentId={id.toString()} />}
        </Card.Body>
        {isAdmin &&
          location.pathname !== '/user/profile' &&
          location.pathname !== '/user/settings' && (
            <Card.Footer className="bg-light">
              <Grid.Row lg={12}>
                <Grid.Col lg={6}>
                  {coach?.id && (
                  <Text.Small>
                    SKY.D:{' 0'}
                    {location.pathname === '/admin/coach-details'
                      ? coach?.id
                      : user?.id}
                    <span
                      className={`ml-2 status-icon ${
                        (coach?.registration_id) ? 'bg-success' : 'bg-secondary'
                      }`}
                    />
                  </Text.Small>
                  )}
                </Grid.Col>
                <Grid.Col className="text-right" lg={6}>
                  {user?.uspa[0]?.merits_uspa_member_expiration && (
                    <Text.Small>
                      USPA: {user?.uspa[0]?.merits_uspa_member_id}
                    </Text.Small>
                  )}
                </Grid.Col>
              </Grid.Row>
            </Card.Footer>
          )}
      </Card>
      {goToStudentDetails && (
        <Button
          block
          outline
          color="primary"
          className="mt-5 mb-5"
          onClick={(e) => {
            e.preventDefault()
            routeToStudent()
          }}
          to="#"
        >
          View Details
        </Button>
      )}
    </>
  )
}

export default observer(UserCard)
