import { useLazyQuery, useMutation } from '@apollo/client'
import FormField from 'components/FormField'
import Modal from 'components/Modal'
import { Formik } from 'formik'
import { GET_JOBS_BY_BIN } from 'graphql/GET_JOBS_BY_BIN'
import { UPDATE_JOBS } from 'graphql/UPDATE_JOBS'
import { toast } from 'react-toastify'
import { Button, Grid, List, Tag, Text } from 'tabler-react'

type GearStorageTransferModalProps = {
  show: boolean
  onHide: () => void
  jobList: any
  bin: number
}
const GearStorageTransferModal = (props: GearStorageTransferModalProps) => {
  const [getJobsByBin] = useLazyQuery(GET_JOBS_BY_BIN)
  const [updateJobs] = useMutation(UPDATE_JOBS, {
    onCompleted: () => {
      toast.success('Items transferred.')
    },
    refetchQueries: ['GET_JOBS_BY_STORAGE']
  })

  const renderForm = ({ handleSubmit, values, handleChange, isSubmitting }) => {
    return (
      <Modal
        open={props.show}
        onClose={props.onHide}
        title={`Move items from Bin: ${props.bin}`}
        content={
          <form>
            <Grid.Row>
              <Grid.Col>
                <List className="pl-0 mt-2">
                  {props.jobList.map((job) => (
                    <li className='nav-item mb-2 pl-0'>
                      {job.user_gear_id && (
                        <Text.Small className="mr-4 text-muted">
                          <i className="fe fe-shopping-bag mr-1 ml-1" />
                          <span className="text-muted">
                            {job?.user_gear?.gear_model_name}{' '}
                            {job?.user_gear?.gear_canopy_size
                              ? job?.user_gear?.gear_canopy_size
                              : ''}
                          </span>
                          <span className="ml-1">
                            {job?.user_gear?.gear_serial}
                          </span>
                          <Tag color="info" className="ml-1">
                            {job?.user_gear?.rig_name}
                          </Tag>
                        </Text.Small>
                      )}
                    </li>
                  ))}
                </List>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col width={3}>
                <FormField
                  name={'bin_number'}
                  label={'Enter New Bin'}
                  value={values.bin_number}
                  onChange={handleChange}
                />
              </Grid.Col>
            </Grid.Row>
          </form>
        }
        actions={
          <>
            <Button
              color="white"
              onClick={props.onHide}
            >
              CANCEL
            </Button>
            <Button
              color="success"
              onClick={handleSubmit}
              disabled={isSubmitting || !values.bin_number}
            >
              MOVE
            </Button>
          </>
        }
      />
    )
  }
  return (
    <Formik
      initialValues={{
        bin_number: ''
      }}
      onSubmit={async (values, actions) => {
        const jobList = await getJobsByBin({
          variables: { binId: Number(values.bin_number) }
        }).then((res) => res.data)
        if (jobList.jobsByBin.length > 0) {
          actions.setFieldError('bin_number', 'Bin is not empty')
          actions.setSubmitting(false)
          return
        }
        await updateJobs({
          variables: {
            jobIds: props.jobList.map((job) => job.id),
            updateJobsInput: {
              job_storage: Number(values.bin_number)
            }
          }
        })

        actions.setSubmitting(false)
        actions.resetForm()
        props.onHide()
        console.log('Submit')
      }}
    >
      {(formikProps) => renderForm(formikProps)}
    </Formik>
  )
}

export default GearStorageTransferModal
