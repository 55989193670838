import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import React from 'react'
import { Button, Form, Grid } from 'tabler-react'
import { GET_JOB_LIST } from '../../graphql/GET_JOB_LIST'
import { UPDATE_JOB } from '../../graphql/UPDATE_JOB'
import Modal from '../Modal'

interface JobNotesProps {
  job: any
  toggleModal: any
  isModalOpen: any
  invoice_id?: number
}

const JobNotesEditForm = ({
  job,
  toggleModal,
  isModalOpen,
  invoice_id
}: JobNotesProps) => {
  const [updateJob] = useMutation(UPDATE_JOB)
  const renderForm = ({
    values,
    handleSubmit,
    handleChange,
    errors,
    setFieldValue
  }) => (
    <>
      <Grid.Row>
        <Grid.Col>
          <Form.Textarea
            label="Notes:"
            name="job_notes"
            placeholder="Job notes"
            rows={3}
            type="text"
            onChange={handleChange}
            value={values.job_notes}
          />
          {errors.job_notes && (
            <span className="field-error text-danger">{errors.job_notes}</span>
          )}
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className={'mt-2'}>
        <Grid.Col width="4">
          <Form.Group label="Bin:">
            <Form.Input
              placeholder="Bin"
              name="job_storage"
              type="text"
              onChange={handleChange}
              value={values.job_storage}
            />
          </Form.Group>
        </Grid.Col>
        <Grid.Col width="12">
          <Button.List className="mt-4" align="right">
            <Button
              color="white"
              onClick={(e) => {
                e.preventDefault()
               toggleModal(!isModalOpen)
              }}
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              form="registrationRequirementForm"
              color="primary"
              onClick={() => handleSubmit()}
            >
              SAVE
            </Button>
          </Button.List>
        </Grid.Col>
      </Grid.Row>
    </>
  )

  return (
    <Modal
      content={
        <>
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: job.id,
              job_notes: job.job_notes,
              job_storage: job.job_storage ?? null
            }}
            onSubmit={async (values, { resetForm }) => {
              await updateJob({
                variables: {
                  updateJobInput: {
                    id: values.id,
                    job_notes: values.job_notes,
                    job_storage: values.job_storage
                      ? Number(values.job_storage)
                      : null
                  }
                },
                refetchQueries: [
                  {
                    query: GET_JOB_LIST,
                    variables: {
                      jobListFilter: {
                        invoice_id: invoice_id
                      }
                    }
                  }
                ]
              })
              resetForm()
              toggleModal(!isModalOpen)
            }}
          >
            {(formikData) => renderForm(formikData)}
          </Formik>
        </>
      }
      open={isModalOpen}
      onClose={() => toggleModal(!isModalOpen)}

    //  actions={
    //    <>
    //      <Button.List>
    //        <Button
    //          color="white"
    //          onClick={(e) => {
    //            e.preventDefault()
    //            toggleModal(!isModalOpen)
    //          }}
    //        >
    //          CANCEL
    //        </Button>
    //      </Button.List>
    //      <Button
     //       color={'primary'}
    //        type={'submit'}
    //        form="registrationRequirementForm"
          //  onClick={() => handleSubmit()}
    //      >
    //        SAVE
    //      </Button>
    //    </>
    //  }

    //  title={'Update Job'}
    />
  )
}

export default JobNotesEditForm
