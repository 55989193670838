import InvoiceDetailsModal from 'components/Invoices/Modals/InvoiceDetailsModal'
import moment from 'moment'
import React, { useState } from 'react'
import { Button, Card, Tag, Text } from 'tabler-react'
import { Types } from '../../types/graphql'
import { formatMoney } from '../../utils/numberFormat'

interface JobGearStorageItemGearProps {
  job: Types.JobContainer
  handleRemoveItem: (jobId: number) => void
}

const linkStyle = {
  background: 'none',
  border: 'none',
  padding: '0',
  font: 'inherit',
  cursor: 'pointer',
  textDecoration: 'none'
}

const JobGearStorageItemGear = ({
  job,
  handleRemoveItem
}: JobGearStorageItemGearProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const totalAmountDue = job?.invoice ? job.invoice.invoice_amount : 0
  const totalPaid =
    job.invoice?.payments.reduce((acc, curr) => {
      if (curr.status === 'Paid') {
        return acc + (curr.total ?? curr.amount)
      }
      return acc
    }, 0) || 0

  return (
    <Card className="mb-2" key={job.id}>
      <Card.Status
        color={
          job.status === 'Hold'
            ? 'teal'
            : job.status === 'Closed' || job?.invoice?.status === 'Paid' || (totalAmountDue && (totalAmountDue - totalPaid <= 0))
            ? 'success'
            : totalAmountDue && (totalAmountDue - totalPaid !== 0)
            ? 'danger'
            : job?.invoice?.status === 'Pending'
            ? ''
            : 'secondary'
        }
        side
      />
      <Card.Body>
        <Text className="d-block">
          <Text.Small className="mr-4 text-muted">
            <strong>
              {job?.student?.first_name} {job?.student?.last_name}
            </strong>
            {job.user_gear_id && (
              <>
                <i className="fe fe-shopping-bag mr-1 ml-1" />
                <span className="text-muted">
                  {job?.user_gear?.gear_model_name}{' '}
                  {job?.user_gear?.gear_canopy_size
                    ? job?.user_gear?.gear_canopy_size
                    : ''}
                </span>
                <span className="ml-1">{job?.user_gear?.gear_serial}</span>
                <Tag color="info" className="ml-1">
                  {job?.user_gear?.rig_name}
                </Tag>
              </>
            )}
          </Text.Small>
        </Text>
        <Text.Small className="mr-2 text-muted">
          <i className="fe fe-calendar mr-1" />
          Intake: <strong>{moment(job.created_on).format('MMM D')}</strong>
          {job.status !== 'Invoice' && job.status !== 'Closed' && (
            <>
              <Tag className="ml-2">{job.status}</Tag>
            </>
          )}
        </Text.Small>
        {job?.completed_on && (
          <Text.Small className="text-muted">
            Completed:
            <span className="">
              <strong
                className={`${
                  moment().diff(moment(job?.completed_on), 'days') > 30
                    ? 'text-danger'
                    : ''
                }`}
              >
                {' '}
                {moment().diff(moment(job?.completed_on), 'days')} days ago
              </strong>
            </span>
          </Text.Small>
        )}
      </Card.Body>
      <Card.Footer>
        <Text className="float-right">
          {totalAmountDue ? (
          <>
          <Button
            color="white"
            size="sm"
            className={totalAmountDue - totalPaid <= 0 ? 'text-success ml-2' : 'ml-2'}
          //  disabled
          >
            {(totalAmountDue - totalPaid) === 0 ? (
              <span>
                <i className='fe fe-check-circle' /> PAID
              </span>
            ) : ('DUE: ' +
              formatMoney(totalAmountDue - totalPaid)
            )}
            {/*formatMoney(totalAmountDue - totalPaid)*/}
            {/*{job?.invoice?.status}*/}
          </Button>

        </>
        ) : ''}
          <Button
            icon="log-out"
            size="sm"
            color="white"
            className="text-muted"
            onClick={() => handleRemoveItem(job.id)}
          />
        </Text>
        <Text className="text-muted">
          {job?.invoice?.id && (
            <Button
              icon="file"
              color="white"
              size="sm"
            //  style={linkStyle}
              //href={`/dzm/invoice/${job?.invoice?.id}`}
              className="text-primary"
              onClick={(e) => {
                e.preventDefault()
                setIsOpen(true)
              }}
            >
              {job?.invoice?.id}
              <span className="text-secondary ml-2">
                {formatMoney(job?.invoice?.invoice_amount)}
              </span>
            </Button>
          )}
        </Text>
      </Card.Footer>
      {job?.invoice?.id && (
        <InvoiceDetailsModal
          isOpen={isOpen}
          invoiceId={job.invoice.id}
          closeModal={setIsOpen}
          key={job.invoice.id}
        />
      )}
    </Card>
  )
}

export default JobGearStorageItemGear
