import { useLazyQuery, useMutation } from '@apollo/client'
import FormField from 'components/FormField'
import { Formik } from 'formik'
import { GET_EMAIL_VALIDATION } from 'graphql/GET_EMAIL_VALIDATION'
import { GET_PHONE_VALIDATION } from 'graphql/GET_PHONE_VALIDATION'
import { UPDATE_USER_MUTATION } from 'graphql/UPDATE_USER_MUTATION'
import { applySnapshot, getSnapshot } from 'mobx-state-tree'
import { useGetUserIsAdmin } from 'modules/common/hooks/useGetCurrentUserType'
import { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { toast } from 'react-toastify'
import { Button, Card, Form, Icon } from 'tabler-react'
import { GET_COACH_EMAIL_VALIDATION } from '../../graphql/GET_COACH_EMAIL_VALIDATION'
import { GET_COACH_PHONE_VALIDATION } from '../../graphql/GET_COACH_PHONE_VALIDATION'
import UserPasswordChangeModal from './Modals/UserPasswordChangeModal'

const CoachContactInfo = ({ coach, coachClone }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isAdmin = useGetUserIsAdmin()
  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => toast.success('Profile Updated.')
  })

  const [validateEmail] = useLazyQuery(GET_EMAIL_VALIDATION, {
    fetchPolicy: 'network-only'
  })
  const [validatePhone] = useLazyQuery(GET_PHONE_VALIDATION, {
    fetchPolicy: 'network-only'
  })
  const [validateCoachEmail] = useLazyQuery(GET_COACH_EMAIL_VALIDATION, {
    fetchPolicy: 'network-only'
  })
  const [validateCoachPhone] = useLazyQuery(GET_COACH_PHONE_VALIDATION, {
    fetchPolicy: 'network-only'
  })

  const mstChangePhone = (e, setFieldValue) => {
    e.preventDefault()
    const target = e.target

    const phone = target.value.split(' ')
    const countryCode = phone.shift().replace(/\D/g, '')

    coachClone.set('phone_number', phone.join('').replace(/\D/g, ''))
    coachClone.set('phone_country_code', Number(countryCode))
  }

  const mstHandleChange = (e) => {
    coachClone.set(e.target.name, e.target.value)
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: coach.id,
        phone_number: coach.phone_number,
        phone_country_code: coach.phone_country_code,
        email: coach.email,
        email_verified: coach.email_verified
      }}
      onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
        let errorFound = false

        const emailData = await validateEmail({
          variables: { email: values.email }
        }).then((res) => {
          return res.data
        })
        const coachEmail = await validateCoachEmail({
          variables: { email: values.email }
        }).then((res) => {
          return res.data
        })
        const phoneData = await validatePhone({
          variables: {
            phone: `${values.phone_country_code}${values.phone_number}`
          }
        }).then((res) => {
          return res.data
        })

        const coachPhone = await validateCoachPhone({
          variables: {
            phone: `${values.phone_country_code}${values.phone_number}`
          }
        }).then((res) => {
          return res.data
        })

        if (
          (emailData?.validateEmail || coachEmail?.validateCoachEmail) && // check if email exists
          coach.email !== values.email // check if email is not the same as the current email
        ) {
          setFieldError('email', 'Email already exists. Contact SUPPORT.')
          errorFound = true
        }

        if (
          (phoneData?.validatePhone || coachPhone?.validateCoachPhone) && // check if phone exists
          coach.phone_number !== values.phone_number
        ) {
          // check if phone is not the same as the current phone
          setFieldError(
            'phone_number',
            'Phone number already exists. Contact SUPPORT.'
          )
          errorFound = true
        }

        if (errorFound) {
          setSubmitting(false)
          return false
        }

        await updateUser({
          variables: {
            user: {
              ...values,
              type: coach.type ?? 'coach'
            }
          }
        })
        coachClone.set('phone_number', values.phone_number)
        coachClone.set('phone_country_code', values.phone_country_code)
        coachClone.set('email', values.email)
        applySnapshot(coach, getSnapshot(coachClone))
        setSubmitting(false)
        //resetForm()
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        setFieldValue
      }) => (
        <form className="card account-update-form " onSubmit={handleSubmit}>
          <Card.Header>
            <Card.Title>
              <Icon name="lock" className="mr-2 ml-0 text-secondary" />
              Sign-in & Security
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Form.Group label="Phone">
              <PhoneInput
                inputProps={{
                  className: 'form-control w-100',
                  name: 'phone_number',
                  required: true,
                  onChange: (e) => {
                    e.preventDefault()
                    const target = e.target

                    const phone = target.value.split(' ')
                    const countryCode = phone.shift().replace(/\D/g, '')

                    setFieldValue(
                      'phone_number',
                      phone.join('').replace(/\D/g, '')
                    )
                    setFieldValue('phone_country_code', Number(countryCode))
                  }
                }}
                value={`${values.phone_country_code}${values.phone_number}`}
              />
              <span className="field-error text-danger">
                {errors.phone_number &&
                  touched.phone_number &&
                  errors.phone_number}
              </span>
            </Form.Group>
            <Form.Group label="Email">
              <FormField
                appendleft={
                  <Button icon={'mail'} color={'secondary'} disabled />
                }
                type="email"
                name="email"
                onChange={(e) => setFieldValue('email', e.target.value)}
                value={values.email}
              />
            </Form.Group>
          </Card.Body>

          <Card.Footer>
            <Button
              className="float-left text-muted"
              disabled={isSubmitting}
              loading={isSubmitting}
              pill
              color="white"
              size="sm"
              onClick={(e) => {
                e.preventDefault()
                setIsModalOpen(true)
              }}
            >
              PASSWORD
            </Button>

            <Button.List align="right">
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                pill
                color="gray-dark"
                size="sm"
                type="submit"
              >
                UPDATE
              </Button>
            </Button.List>
          </Card.Footer>
          <UserPasswordChangeModal
            isModalOpen={isModalOpen}
            toggleModal={() => setIsModalOpen(!isModalOpen)}
            user={coach}
          />
        </form>
      )}
    </Formik>
  )
}

export default CoachContactInfo
