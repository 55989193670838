import React from 'react'
import autoCompleteStyles from './autocompleteStyles'
import useReactRouter from 'use-react-router'
import { useAutocomplete } from '../../../hooks/useAutocomplete'
import { GET_TEAMS_BY_FILTER_QUERY } from '../../../graphql/GET_TEAMS_BY_FILTER_QUERY'
import AsyncSelect from 'react-select/async'
import { Types } from '../../../types/graphql'
import { Tag, Text } from 'tabler-react';


const TeamSearchField = () => {
  const { history } = useReactRouter()
  const { loadOptions } = useAutocomplete({
    query: GET_TEAMS_BY_FILTER_QUERY,
    minInputLength: 1,
  })

  return (
    <>
      <AsyncSelect
        backspaceRemovesValue={true}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null
        }}
        escapeClearsValue={true}
        getOptionLabel={(option: Types.TeamSearch) => {
          return (
            <Text className="cursor-pointer">
              {option?.id ? (
                <>
                  <span
                    className={option.is_published ? 'text-primary' : 'text-secondary'}
                  >{option?.name}</span>
                  <Tag
                    color={option.is_public ? 'primary' : 'secondary'}
                    className="float-right"
                  >
                    {option?.id}
                  </Tag>
                  <Text.Small className="d-block text-muted">{option.slug}</Text.Small>
                </>
              ) : (
                ''
              )}
            </Text>
          );
        }}
        getOptionValue={(option: Types.TeamSearch) =>
          option.id
        }
        isClearable={true}
        loadOptions={loadOptions}
        onChange={(e) => {
          if (e) {
          //  history.push(`/${e.slug}`)
          history.push(`/team-details/${e.id}`)
          }
        }}
        placeholder="Search Teams"
        styles={autoCompleteStyles}
      />
    </>
  )
}

export default TeamSearchField
