import React, { useContext, useState } from 'react';
import { Button } from 'tabler-react';
import RigModal from './RigModal';
import GearCardModalForm from './Modal/GearCardModalForm';
import { observer } from 'mobx-react';
import { UserStoreContext } from '../../contexts/userStoreContext';
import { EUserTypes } from '../User/userTypes';
import { useRootStore } from '../../hooks';

const RigGearModalButtons = () => {
  const rootStore = useRootStore();
  const { currentUser } = rootStore;
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;
  const student_id = currentUser.type === EUserTypes.student ? currentUser.id : parseInt(user.id);

  const [isGearModalOpen, setIsGearModalOpen] = useState<boolean>(false);
  const [isRigModalOpen, setIsRigModalOpen] = useState<boolean>(false);

  const handleRigToggleModal = (isOpen) => {
    setIsRigModalOpen(isOpen);
  };
  const handleToggleModal = (isOpen) => {
    setIsGearModalOpen(isOpen);
  };
  return (
    <>
      <RigModal
        rig={null}
        toggleModal={handleRigToggleModal}
        isModalOpen={isRigModalOpen}
      />
      <GearCardModalForm
        userGear={null}
        toggleModal={handleToggleModal}
        isModalOpen={isGearModalOpen}
        studentId={student_id}
      />
      <Button
        className="mr-2"
        icon='plus'
        color='primary'
        onClick={() => {
          handleToggleModal(true);
        }
        }
      >
        GEAR
      </Button>
      <Button
        icon='plus'
        color='info'
        onClick={() => {
          handleRigToggleModal(true);
        }
        }
      >
        RIG
      </Button>
    </>
  );
};

export default observer(RigGearModalButtons);
