import { EHostnames } from 'components/constants'
import { useRootStore } from 'hooks'
import React from 'react'
import { Helmet } from 'react-helmet'
import combat_logo from '../../assets/images/combat_logo.png'
import fairfights_logo from '../../assets/images/fairfights_logo.png'
import {
  default as logo,
  default as skycru_logo
} from '../../assets/images/logo.png'
import mxcru_logo from '../../assets/images/mxcru_logo.jpg'

export const SiteFavicon = () => {
  const { domainData } = useRootStore()

  switch (domainData.key) {
    case EHostnames.LOCAL:
      return (
        <Helmet>
          <link
            rel="icon"
            href={`${process.env.PUBLIC_URL}/SKYCRU_favicon-100x100.png`}
          />
        </Helmet>
      )
    case EHostnames.FAIRFIGHTS:
      return (
        <Helmet>
          <link
            rel="icon"
            href={`${process.env.PUBLIC_URL}/check_verified.png`}
          />
          <link
            rel="apple-touch-icon"
            href={`${process.env.PUBLIC_URL}/check_verified.png`}
          />
        </Helmet>
      )
    case EHostnames.COMBAT:
      return (
        <Helmet>
          <link
            rel="icon"
            href={`${process.env.PUBLIC_URL}/combat_favicon-100x100.png`}
          />
          <link
            rel="apple-touch-icon"
            href={`${process.env.PUBLIC_URL}/combat_favicon-100x100.png`}
          />
        </Helmet>
      )
    case EHostnames.SKYCRU:
      return (
        <Helmet>
          <link
            rel="icon"
            href={`${process.env.PUBLIC_URL}/SKYCRU_favicon-100x100.png`}
          />
          <link
            rel="apple-touch-icon"
            href={`${process.env.PUBLIC_URL}/SKYCRU_favicon-100x100.png`}
          />
        </Helmet>
      )
    default:
      return (
        <Helmet>
          <link
            rel="icon"
            href={`${process.env.PUBLIC_URL}/combat_favicon-100x100.png`}
          />
        </Helmet>
      )
  }
}

type SiteLogoProps = {
  customClass?: string
}
const SiteLogo: React.FC<SiteLogoProps> = ({ customClass }) => {
  const getLogoSrc = () => {
    const { domainData } = useRootStore()
    switch (domainData?.key) {
      case EHostnames.LOCAL:
        return skycru_logo
      case EHostnames.SKYCRU:
        return skycru_logo
      case EHostnames.MXCRU:
        return skycru_logo
      case EHostnames.COMBAT:
        return combat_logo
      case EHostnames.FAIRFIGHTS:
        return fairfights_logo
      default:
        return logo
    }
  }

  return <img src={getLogoSrc()} className={customClass || 'w-75'} alt="logo" />
}

export default SiteLogo
