import { useMutation, useQuery } from '@apollo/client'
import { Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Button, Form, Grid, Tag, Text } from 'tabler-react'
import * as Yup from 'yup'
import { UserStoreContext } from '../../contexts/userStoreContext'
import { ADD_RIG } from '../../graphql/ADD_RIG'
import { GET_RIGS } from '../../graphql/GET_RIGS'
import { GET_STUDENT_GEARS } from '../../graphql/GET_STUDENT_GEARS'
import { UPDATE_RIG } from '../../graphql/UPDATE_RIG'
import { UPDATE_RIG_GEARS } from '../../graphql/UPDATE_RIG_GEARS'
import { useRootStore } from '../../hooks'
import { Types } from '../../types/graphql'
import FormField from '../FormField'
import Modal from '../Modal'
import { EUserTypes } from '../User/userTypes'

interface RigModalFormProps {
  isModalOpen: boolean
  toggleModal: any
  rig: Types.Rig
}

const RigFormValidation = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
  container: Yup.number().min(1).required('Container is required')
})

const RigModal = ({ isModalOpen, toggleModal, rig }: RigModalFormProps) => {
  const rootStore = useRootStore()
  const { currentCoachTeam, currentUser } = rootStore
  const userStore = useContext(UserStoreContext)
  const { user } = userStore
  const student_id =
    currentUser?.type === EUserTypes.student
      ? currentUser.id
      : parseInt(user.id)

  const [userGears, setUserGears] = useState<Types.UserGear[]>([])
  const [rigGears, setRigGears] = useState(null)
  const { loading, data } = useQuery(GET_STUDENT_GEARS, {
    variables: {
      getUserGearOptions: { student_id: student_id }
    }
  })

  useEffect(() => {
    if (!loading && data) setUserGears(data.getUserGears)
  }, [rig, data, loading])

  useEffect(() => {
    const currentRigGears = {
      container: rig?.rig_gear.find((item) => item.gear_type_id === 5)
        ?.user_gear_id,
      main: rig?.rig_gear.find((item) => item.gear_type_id === 1)?.user_gear_id,
      reserve: rig?.rig_gear.find((item) => item.gear_type_id === 2)
        ?.user_gear_id,
      aad: rig?.rig_gear.find((item) => item.gear_type_id === 4)?.user_gear_id
    }
    setRigGears(currentRigGears)
  }, [rig?.rig_gear])

  const [addRig] = useMutation(ADD_RIG)
  const [updateRig] = useMutation(UPDATE_RIG)
  const [updateRigGears] = useMutation(UPDATE_RIG_GEARS)

  const handleClose = () => {
    toggleModal(false)
  }

  const handleSubmitRigGears = (rig_id, gears) => {
    updateRigGears({
      variables: {
        user_rig_id: parseInt(rig_id),
        user_gear_ids: gears.filter(Number)
      },
      refetchQueries: [
        {
          query: GET_RIGS,
          variables: {
            rigListFilter: {
              student_id: student_id
            }
          }
        },
        {
          query: GET_STUDENT_GEARS,
          variables: {
            getUserGearOptions: { student_id: student_id }
          }
        }
      ]
    })
  }

  const customFilter = (option, searchText) => {
    if (
      option.data.gear_model.name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.gear_serial
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      option.data.rig?.name.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true
    } else {
      return false
    }
  }

  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldValue,
    errors
  }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row className="mb-3">
        <Grid.Col>
          <Text.Small className="d-block text-muted">
            <i>Tips:</i>
          </Text.Small>
          <Text.Small className="d-block text-muted">
            <i>You will need to add gear before it can be selected.</i>
          </Text.Small>
          <Text.Small className="d-block text-muted">
            <i>To remove gear from this rig, click "X" and SAVE.</i>
          </Text.Small>
          <Text.Small className="d-block text-muted">
            <i>
              Selecting gear associated with another rig will remove it from
              it's current rig and add it to this rig.
            </i>
          </Text.Small>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mb-3">
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="Rig Name">
            <FormField
              type="text"
              name="name"
              placeholder="Give this rig a name..."
              onChange={handleChange}
              value={values.name}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mb-5">
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="Container">
            <Select
              name="container"
              placeholder="Select Container..."
              getOptionLabel={(option: Types.UserGear) => {
                return (
                  <>
                    {option?.gear_model.name + ' ' + option.gear_serial + ' '}
                    {option?.name ? <Tag>{option?.name}</Tag> : ''}{' '}
                    {option?.rig?.name ? (
                      <Tag color="primary">{option?.rig?.name}</Tag>
                    ) : (
                      ''
                    )}
                  </>
                )
              }}
              getOptionValue={(option: Types.UserGear) => option.id}
              filterOption={customFilter}
              isMulti={false}
              options={userGears.filter(
                (userGear) => userGear.gear_model.gear_type_id === 5
              )}
              onChange={(param) => setFieldValue('container', param.id)}
              value={userGears.filter(
                (userGear) => userGear.id === values.container
              )}
            />

            <span className="field-error text-danger">
              {errors.container && errors.container}
            </span>
          </Form.Group>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="Main Canopy">
            <Select
              isClearable={true}
              name="main"
              placeholder="Select Main Canopy..."
              getOptionLabel={(option: Types.UserGear) => {
                return (
                  <>
                    {option?.gear_model.name +
                      ' ' +
                      option.gear_canopy_size +
                      ' ' +
                      option.gear_serial +
                      ' '}
                    {option?.name ? <Tag>{option?.name}</Tag> : ''}{' '}
                    {option?.rig?.name ? (
                      <Tag color="primary">{option?.rig?.name}</Tag>
                    ) : (
                      ''
                    )}
                  </>
                )
              }}
              getOptionValue={(option: Types.UserGear) => option.id}
              isMulti={false}
              options={userGears.filter(
                (userGear) => userGear.gear_model.gear_type_id === 1
              )}
              value={userGears.filter(
                (userGear) => userGear.id === values.main
              )}
              filterOption={customFilter}
              onChange={(param) => setFieldValue('main', param?.id)}
            />
            <span className="field-error text-danger"></span>
          </Form.Group>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="Reserve Canopy">
            <Select
              isClearable={true}
              name="reserve"
              placeholder="Select Reserve Canopy..."
              getOptionLabel={(option: Types.UserGear) => {
                return (
                  <>
                    {option?.gear_model.name +
                      ' ' +
                      option.gear_canopy_size +
                      ' ' +
                      option.gear_serial +
                      ' '}
                    {option?.name ? <Tag>{option?.name}</Tag> : ''}{' '}
                    {option?.rig?.name ? (
                      <Tag color="primary">{option?.rig?.name}</Tag>
                    ) : (
                      ''
                    )}
                  </>
                )
              }}
              getOptionValue={(option: Types.UserGear) => option.id}
              isMulti={false}
              options={userGears.filter(
                (userGear) => userGear.gear_model.gear_type_id === 2
              )}
              value={userGears.filter(
                (userGear) => userGear.id === values.reserve
              )}
              filterOption={customFilter}
              onChange={(param) => setFieldValue('reserve', param?.id)}
            />
            <span className="field-error text-danger"></span>
          </Form.Group>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="AAD">
            <Select
              isClearable={true}
              name="aad"
              placeholder="Select AAD (optional)"
              getOptionLabel={(option: Types.UserGear) => {
                return (
                  <>
                    {option?.gear_model.name + ' ' + option.gear_serial + ' '}
                    {option?.name ? <Tag>{option?.name}</Tag> : ''}{' '}
                    {option?.rig?.name ? (
                      <Tag color="primary">{option?.rig?.name}</Tag>
                    ) : (
                      ''
                    )}
                  </>
                )
              }}
              getOptionValue={(option: Types.UserGear) => option.id}
              isMulti={false}
              options={userGears.filter(
                (userGear) => userGear.gear_model.gear_type_id === 4
              )}
              value={userGears.filter((userGear) => userGear.id === values.aad)}
              filterOption={customFilter}
              onChange={(param) => setFieldValue('aad', param?.id)}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Button
        color="primary"
        disabled={isSubmitting}
        className="float-right"
        onClick={handleSubmit}
      >
        {isSubmitting ? 'Processing...' : 'SAVE'}
      </Button>
      <Button
        color="white"
        className="float-right"
        onClick={(e) => {
          e.preventDefault()
          handleClose()
        }}
      >
        CANCEL
      </Button>
    </form>
  )

  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          validationSchema={RigFormValidation}
          initialValues={{
            id: rig?.id ?? null,
            name: rig?.name ?? '',
            container: rigGears?.container ?? '',
            main: rigGears?.main ?? '',
            reserve: rigGears?.reserve ?? '',
            aad: rigGears?.aad ?? ''
          }}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            if (values.id) {
              const updated = await updateRig({
                variables: {
                  updateRigInput: {
                    id: parseInt(values.id),
                    name: values.name
                  }
                }
              })

              if (updated) {
                handleSubmitRigGears(values.id, [
                  values.container,
                  values.main,
                  values.reserve,
                  values.aad
                ])
              }
            } else {
              const newRig: any = await addRig({
                variables: {
                  addRigInput: {
                    name: values.name,
                    student_id: student_id,
                    team_id: currentCoachTeam?.id ?? null
                  }
                }
              })

              if (newRig?.data?.addRig) {
                handleSubmitRigGears(newRig?.data?.addRig.id, [
                  values.container,
                  values.main,
                  values.reserve,
                  values.aad
                ])
              }
            }

            setSubmitting(false)
            resetForm()
            toast.success('Rig saved!')
            toggleModal(!isModalOpen)
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      onClose={() => toggleModal(!isModalOpen)}
      title={rig?.name ?? ''}
    />
  )
}

export default RigModal
