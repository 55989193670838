import { useQuery } from '@apollo/client';
import { IconParachute } from '@tabler/icons-react';
import { GET_EXPIRING_RESERVE_LOGS } from 'graphql/GET_EXPIRING_RESERVE_LOGS';
import { GET_EXPIRING_RESERVE_LOGS as GET_EXPIRING_RESERVE_LOGS_TYPE } from 'graphql/types/GET_EXPIRING_RESERVE_LOGS';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Alert,
  Button,
  Card,
  Dimmer,
  Form,
  Grid,
  Icon,
  Table,
  Text
} from 'tabler-react';
import { GET_LOCATION_BY_ID } from '../../graphql/GET_LOCATION_BY_ID';
import { GET_USER_RESERVE_LOGS } from '../../graphql/GET_USER_RESERVE_LOGS';
import { useRootStore } from '../../hooks';
import { EOrderOptions } from '../../hooks/useAutocomplete';
import { EUserTypes } from '../User/userTypes';
import ReserveLogAdminForm from './Forms/ReserveLogAdminForm';
import ReserveLogItem from './ReserveLogItem';
import moment from 'moment';
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType';
import { Types } from '../../types/graphql'

export const DEFAULT_RESERVE_LOG_LIMIT = 50;
const ReserveLogList = () => {
  const { currentUser, currentCoachTeam } = useRootStore();
  const { isAdmin, isCoachSuperAdmin, isRigger, isDzm } = useGetCurrentUserType();

  const [reserveLogs, setReservelogs] = useState<
    Types.ReserveLog[]
  >([]);
  const [reserveLogClone, setReservelog] = useState(null);

  // const dateFilterRef = useRef(null);

  const [page, setPage] = useState<number>(2);
  const options = {
    date: undefined,
    team_id: undefined
  };
  if (currentUser.type !== EUserTypes.admin)
    options.team_id = currentCoachTeam.id;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    loading: userReserveLogLoading,
    data: userReserveLogData,
    fetchMore
  } = useQuery(GET_USER_RESERVE_LOGS, {
    variables: {
      order: EOrderOptions.newest,
      reserveLogOptions: {
        ...options,
        limit: DEFAULT_RESERVE_LOG_LIMIT,
        offset: 0
      }
    }
  });

  const { data: locationData } = useQuery(GET_LOCATION_BY_ID, {
    variables: {
      id: currentCoachTeam?.default_location_id.toString()
    }
  });

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (!userReserveLogLoading && userReserveLogData) {
      setReservelogs(userReserveLogData.getReserveLogs);
    }
  }, [userReserveLogLoading, userReserveLogData]);

  const { data: expiringThisMonthData, loading: expiringThisMonthLoading } =
    useQuery<GET_EXPIRING_RESERVE_LOGS_TYPE>(GET_EXPIRING_RESERVE_LOGS, {
      variables: {
        team_id: currentCoachTeam?.id,
        month: 'current'
      }
    });

  const { data: expiringNextMonthData, loading: expiringNextMonthLoading } =
    useQuery<GET_EXPIRING_RESERVE_LOGS_TYPE>(GET_EXPIRING_RESERVE_LOGS, {
      variables: {
        team_id: currentCoachTeam?.id,
        month: 'next'
      }
    });

  const expiringThisMonthRows = useMemo(
    () =>
      expiringThisMonthData?.getExpiringReserveLogs.map(
        ({
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          email,
          event_date
        }) => {
          return {
            first_name,
            last_name,
            phone_country_code,
            phone_number,
            email,
            event_date: moment(event_date).format('MM/DD/YYYY'),
            expires: moment(event_date).add(180, 'days').format('MM/DD/YYYY')
          };
        }
      ) ?? [],
    [expiringThisMonthData?.getExpiringReserveLogs, expiringThisMonthLoading]
  );

  const expiringNextMonthRows = useMemo(
    () =>
      expiringNextMonthData?.getExpiringReserveLogs.map(
        ({
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          email,
          event_date
        }) => ({
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          email,
          event_date: moment(event_date).format('MM/DD/YYYY'),
          expires: moment(event_date).add(180, 'days').format('MM/DD/YYYY')
        })
      ) ?? [],
    [expiringNextMonthData?.getExpiringReserveLogs, expiringNextMonthLoading]
  );

  const onFetchMore = () => {
    setPage((prevNum) => prevNum + 1);
    return fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }

        return {
          ...cache,
          getReserveLogs: [
            ...cache?.getReserveLogs ?? [],
            ...fetchMoreResult?.getReserveLogs ?? []
          ]
        };
      },
      variables: {
        order: EOrderOptions.newest,
        reserveLogOptions: {
          limit: DEFAULT_RESERVE_LOG_LIMIT,
          offset: DEFAULT_RESERVE_LOG_LIMIT * (page - 1),
          ...options
        }
      }
    });
  };

  // const handleFilterDate = async () => {
  //   options.date = dateFilterRef.current;
  //   await refetch({
  //     order: EOrderOptions.newest,
  //     reserveLogOptions: {
  //       ...options,
  //       limit: DEFAULT_RESERVE_LOG_LIMIT,
  //       offset: 0
  //     }
  //   });
  // };

  return (
    <Grid.Row>
      {(isAdmin || isRigger) && (
        <Grid.Col xs={12} sm={12} lg={3}>
          {isCoachSuperAdmin && (
          <Card>
            <Card.Header>
              <Card.Title>
                <Icon name="bell" className="mr-2 ml-0 text-blue" />
                Reminders
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Group label="Frequency">
                <Form.Input
                  name="example-text-input"
                  placeholder="30 days before"
                  disabled
                />
              </Form.Group>
              <Form.Group label="Delivery Method">
                <Form.Input
                  name="example-text-input"
                  placeholder="Email"
                  disabled
                />
              </Form.Group>
            </Card.Body>
          </Card>
          )}
          <Card>
            <Card.Header>
              <Card.Title>
                <Icon name="file-text" className="mr-2 ml-0 text-blue" />
                Reminder content
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Textarea
                name="note"
                placeholder="Hello {Customer}, this is {Company} reminding you that your reserve expires on {Date}. If you need to contact us please call {company_phone}"
                rows={6}
                type="text"
                disabled
              />
              {isCoachSuperAdmin && (
              <Button
                block
                icon="mail"
                color="primary"
                className="mt-2"
                disabled
              >
                EMAIL REMINDERS
              </Button>
              )}
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>
              <Card.Title>
                <Icon
                  name="alert-triangle"
                  className="mr-2 ml-0 text-warning"
                />
                Expiring Soon
              </Card.Title>
              <Card.Options>
                <span className="mr-2">
                  {expiringThisMonthRows.length + expiringNextMonthRows.length}
                </span>
              </Card.Options>
            </Card.Header>
            <Card.Body>
              <div className="d-flex justify-content-between">
                <CSVLink
                  data={expiringThisMonthRows}
                  filename="reserve-logs-this-month.csv"
                >
                  <Text>
                    This Month
                    <Icon name="download" className="ml-2" />
                  </Text>
                </CSVLink>

                <Text className="text-muted">
                  {expiringThisMonthRows.length}
                </Text>
              </div>
              <div className="d-flex justify-content-between">
                <CSVLink
                  data={expiringNextMonthRows}
                  filename="reserve-logs-next-month.csv"
                >
                  <Text>
                    Next Month
                    <Icon name="download" className="ml-2" />
                  </Text>
                </CSVLink>
                <Text className="text-muted">
                  {expiringNextMonthRows.length}
                </Text>
              </div>
            </Card.Body>
          </Card>
        </Grid.Col>
      )}
      <Grid.Col xs={12} sm={12} lg={isDzm ? '12' : '9'}>
        <Card>
          <Card.Header>
            <Card.Title>
              <IconParachute size={18} className="mr-2 ml-0 text-primary" />
              Reserve Logs
            </Card.Title>

            <Card.Options>
              {/* temporarily hide until this is working correctly.
              <div className={'position-relative'}>
                <input
               // disabled temporarily until this is working correctly. filter does not display correct dates.
                  className={`form-control`}
                  type={'date'}
                  name='event_date'
                  disabled
                  onChange={(e) => {
                    dateFilterRef.current = e.target.value.length === 0 ? null : e.target.value;
                    handleFilterDate();
                  }
                  }
                  value={dateFilterRef.current}
                  max={new Date().toISOString().split('T')[0]}
                />
              </div>
*/}
              {!isAdmin && (
                <Button
                  className="mr-3"
                  color="primary"
                  icon="plus"
                  size="sm"
                  onClick={() => {
                    setReservelog({
                      student_id: null,
                      student: { first_name: '', last_name: '' },
                      location: locationData?.location,
                      location_id: currentCoachTeam?.default_location_id,
                      team: { id: currentCoachTeam?.id },
                      created_by: currentUser.id
                    });
                    handleToggleModal();
                  }}
                >
                  REPACK
                </Button>
              )}
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Dimmer
              active={userReserveLogLoading}
              loader={userReserveLogLoading}
            >
              <InfiniteScroll
                className="overflow-visible pb-3"
                dataLength={reserveLogs.length}
                hasMore={reserveLogs.length < (reserveLogs[0]?.total || 0)}
                loader={<Dimmer active={true} loader={true} />}
                next={onFetchMore}
                scrollThreshold="210px"
                endMessage={
                  <Alert className="text-center mt-5" type="primary">
                    No {reserveLogs.length !== 0 && 'more'} reserve history
                    recorded.
                  </Alert>
                }
              >
                <Grid.Col width={12}>
                  <Table
                    responsive
                    className="card-table table-vcenter text-nowrap"
                  >
                    <Table.Header>
                      <Table.Row>
                        {currentUser.type === EUserTypes.admin && (
                          <Table.ColHeader className="w-1">ID</Table.ColHeader>
                        )}
                        <Table.ColHeader>Pack Date</Table.ColHeader>
                        <Table.ColHeader>Jumper</Table.ColHeader>
                        {currentUser.type === EUserTypes.admin && (
                          <>
                            <Table.ColHeader>Location</Table.ColHeader>
                            <Table.ColHeader>Team</Table.ColHeader>
                          </>
                        )}
                        <Table.ColHeader>Rigger</Table.ColHeader>
                        <Table.ColHeader>Due Date</Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {reserveLogs.map(
                        (
                          reserveLog: Types.ReserveLog,
                          idx: number
                        ) => (
                          <Table.Row key={idx}>
                            <ReserveLogItem
                              reserveLog={reserveLog}
                              toggleModal={() => handleToggleModal()}
                              setReservelog={setReservelog}
                            />
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                </Grid.Col>
              </InfiniteScroll>
            </Dimmer>
          </Card.Body>
          <ReserveLogAdminForm
            isModalOpen={isModalOpen}
            userReserveLog={reserveLogClone}
            toggleModal={() => handleToggleModal()}
          />
        </Card>
      </Grid.Col>
    </Grid.Row>
  );
};

export default observer(ReserveLogList);
