import { useMutation, useQuery } from "@apollo/client";
import { UserStoreContext } from "contexts/userStoreContext";
import { ADD_ROLE_TO_STAFF } from "graphql/ADD_ROLE_TO_STAFF";
import { GET_USER_ROLES } from "graphql/GET_USER_ROLES";
import { REMOVE_ROLE_FROM_STAFF } from "graphql/REMOVE_ROLE_FROM_STAFF";
import { RETRIEVE_TEAM_STAFF } from "graphql/RETRIEVE_TEAM_STAFF";
import { GET_USER_ROLES as GET_USER_ROLES_TYPE } from "graphql/types/GET_USER_ROLES";
import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import {
  ECoachUserRoles,
  EAcademyUserRoles,
  EPromoterUserRoles,
  EDZMUserRoles,
  EEventsUserRoles,
  EManufacturerUserRoles,
  EOrgUserRoles,
  ERiggerUserRoles,
  EWtmUserRoles,
} from "modules/user/roles/constants";
import React, { FC, useContext, useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useLocation } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { Form, Grid, Tag } from "tabler-react";
import { Types } from "../../../../../types/graphql";
import { formatMoney } from "../../../../../utils/numberFormat";

export interface UserRoleFormProps {
  action: "addCoachRole" | "addStudentRole";
  coachId?: number;
  studentId?: number;
}

const UserRoleForm: FC<UserRoleFormProps> = ({
  action,
  coachId,
  studentId,
}) => {
  const { user } = useContext(UserStoreContext);
  const { currentCoachTeam } = useRootStore();
  const {
    isCoach,
    isRigger,
    isDzm,
    isEventOrganizer,
    isManufacturer,
    isWtm,
    isOrg,
    isAcademy,
    isPromoter
  } = useGetCurrentUserType();
  const { search } = useLocation();

  const teamId = new URLSearchParams(search).get("team_id");

  const studentIdArg =
    action === "addStudentRole" ? studentId ?? Number(user?.id) : undefined;

  const [customComp, setCustomComp] = useState(0);

  const { error, data } = useQuery<GET_USER_ROLES_TYPE>(GET_USER_ROLES, {
    variables: {
      params: {
        studentId: studentIdArg,
        coachId,
        teamId: currentCoachTeam?.id ?? Number(teamId),
      },
    },
  });

  const existingRolesRow = useMemo(
    () =>
      data?.getUserRolesByTeamId.map(({ id, name, compensation }) => ({
        id,
        name,
        compensation,
      })) ?? [],
    [data?.getUserRolesByTeamId],
  );

  const userRolesOptions = useMemo(
    () =>
      data?.retrieveUserRoleOptions
        .filter(
          (userRole: Types.UserRole) =>
            userRole.name ===
            (isDzm
              ? EDZMUserRoles[userRole.key]
              : isRigger
              ? ERiggerUserRoles[userRole.key]
              : isEventOrganizer
              ? EEventsUserRoles[userRole.key]
              : isCoach
              ? ECoachUserRoles[userRole.key]
              : isAcademy
              ? EAcademyUserRoles[userRole.key]
              : isPromoter
              ? EPromoterUserRoles[userRole.key]
              : isManufacturer
              ? EManufacturerUserRoles[userRole.key]
              : isOrg
              ? EOrgUserRoles[userRole.key]
              : isWtm
              ? EWtmUserRoles[userRole.key]
              : ""),
        )
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [data?.retrieveUserRoleOptions],
  );

  const [addRoleToStaff] = useMutation(ADD_ROLE_TO_STAFF, {
    onError: (error) => toast.error(error?.graphQLErrors[0]?.message),
    onCompleted: () => toast.success("Role added."),
  });

  const [removeRoleFromStaff] = useMutation(REMOVE_ROLE_FROM_STAFF, {
    onCompleted: () => toast.success("Role removed."),
  });

  if (error) {
    return <p>{`${error.message}`}</p>;
  }

  const rolesOptsSelectCb = async (params: Types.UserRole) => {
    if (!params) {
      return;
    }

    await addRoleToStaff({
      update: (
        cache,
        {
          data: {
            addRoleToStaff: { roles },
          },
        },
      ) => {
        if (!roles) {
          return cache;
        }

        const query = {
          query: GET_USER_ROLES,
          variables: {
            params: {
              coachId,
              studentId: studentIdArg,
              teamId: currentCoachTeam.id,
            },
          },
        };

        const { getUserRolesByTeamId } = cache.readQuery<GET_USER_ROLES_TYPE>({
          ...query,
        });

        cache.writeQuery({
          data: {
            getUserRolesByTeamId: {
              ...getUserRolesByTeamId,
              ...roles,
            },
          },
          ...query,
        });
      },
      variables: {
        teamStaff: {
          coachId,
          studentId: studentIdArg,
          teamId: currentCoachTeam?.id ?? Number(teamId),
          roleId: params.id,
          compensation: customComp,
        },
      },
      refetchQueries: [
        {
          query: RETRIEVE_TEAM_STAFF,
          variables: {
            team_id: currentCoachTeam?.id ?? Number(teamId),
          },
        },
        {
          query: GET_USER_ROLES,
          variables: {
            params: {
              coachId,
              studentId: studentIdArg,
              teamId: currentCoachTeam?.id ?? Number(teamId),
            },
          },
        },
      ],
    });
  };

  return (
    <>
      <Grid.Row className="mb-3">
        <Grid.Col>
          <Form.Group>
            <Form.InputGroup>
              <Select
                className={"w-50"}
                isClearable={true}
                getOptionLabel={(option: Types.UserRole) => option.name}
                getOptionValue={(option: Types.UserRole) => option.id}
                options={userRolesOptions}
                onChange={rolesOptsSelectCb}
                value={null}
                placeholder="Select role..."
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
              {(isDzm || isWtm) && (
                <NumericFormat
                  className={"w-25 ml-2 text-center border-0 text-success"}
                  name="custom_comp"
                  placeholder="Amount"
                  prefix="$"
                  //  value={customComp}
                  thousandSeparator=","
                  onChange={(e) => {
                    const value = Number(e.target.value.replace(/[$,]+/g, ""));
                    setCustomComp(value);
                  }}
                />
              )}
            </Form.InputGroup>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Tag.List>
            {existingRolesRow?.map((role: Types.UserRole) => (
              <Tag
                key={role.id}
                remove
                onClick={async () => {
                  await removeRoleFromStaff({
                    update: (cache, { data: { removeRoleFromStaff } }) => {
                      if (!removeRoleFromStaff) {
                        return cache;
                      }

                      const query = {
                        query: GET_USER_ROLES,
                        variables: {
                          params: {
                            coachId,
                            studentId: studentIdArg,
                            teamId: currentCoachTeam?.id ?? Number(teamId),
                          },
                        },
                      };

                      const { getUserRolesByTeamId } =
                        cache.readQuery<GET_USER_ROLES_TYPE>({
                          ...query,
                        });

                      const updatedRoles = getUserRolesByTeamId.filter(
                        (userRole: Types.UserRole) => userRole.id !== role.id,
                      );

                      cache.writeQuery({
                        data: {
                          getUserRolesByTeamId: updatedRoles,
                        },
                        ...query,
                      });
                    },
                    variables: {
                      teamStaff: {
                        coachId,
                        roleId: role.id,
                        studentId: studentIdArg,
                        teamId: currentCoachTeam?.id ?? Number(teamId),
                      },
                    },
                    refetchQueries: [
                      {
                        query: RETRIEVE_TEAM_STAFF,
                        variables: {
                          team_id: currentCoachTeam?.id ?? Number(teamId),
                        },
                      },
                      {
                        query: GET_USER_ROLES,
                        variables: {
                          params: {
                            coachId,
                            studentId: studentIdArg,
                            teamId: currentCoachTeam?.id ?? Number(teamId),
                          },
                        },
                      },
                    ],
                  });
                }}
              >
                {role.name}{" "}
                {role.compensation &&
                  (isDzm || isWtm) &&
                  `-${formatMoney(Number(role.compensation))}`}
              </Tag>
            ))}
          </Tag.List>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default observer(UserRoleForm);
