import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useRootStore } from 'hooks'
import { Helmet } from 'react-helmet'
import useReactRouter from 'use-react-router'
import StudentSignupGearRig from '../components/Gear/StudentSignupGearRig'
import StudentSignupMerits from '../components/Students/Forms/StudentSignupMerits'
import SignUpForm from '../components/User/Forms/SignUpForm'
import { UserStoreProvider } from '../contexts/userStoreContext'
import FreshChatWidget from '../widgets/FreshChatWidget'
import SiteLogo, { SiteFavicon } from './layout/SiteLogo'

const SignUpPage = (props: any) => {
  const { history } = useReactRouter()
  const [step, setStep] = useState(0)
  useEffect(() => {
    if (step === 3) {
      history.push('/login')
    }
  }, [step])
  const { domainData } = useRootStore()
  return (
    <>
      <SiteFavicon />
      <Helmet>
        <title>{domainData.name}</title>
      </Helmet>
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className={`col ${step === 0 && 'col-login'} mx-auto`}>
                <div className="text-center mb-6">
                  <Link to="/">
                    <SiteLogo />
                  </Link>
                </div>
                <UserStoreProvider>
                  {step === 0 && (
                    <div className="col col-login mx-auto">
                      <SignUpForm {...props} setStep={setStep} />
                    </div>
                  )}

                  {/*{step === 1 &&*/}
                  {/*  <div className='col mx-auto'>*/}
                  {/*    <StudentSignupActivity setStep={setStep} />*/}
                  {/*  </div>*/}
                  {/*}*/}

                  {step === 1 && (
                    <div className="col mx-auto">
                      <StudentSignupGearRig setStep={setStep} />
                    </div>
                  )}

                  {step === 2 && (
                    <div className="col mx-auto">
                      <StudentSignupMerits setStep={setStep} />
                    </div>
                  )}
                </UserStoreProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FreshChatWidget />
    </>
  )
}

export default SignUpPage
