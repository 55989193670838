import { useMutation, useQuery } from '@apollo/client'
import CoachContactInfo from 'components/User/CoachContactInfo'
import EditProfileForm from 'components/User/Forms/EditProfileForm'
import UserCard from 'components/User/Profile/UserCard'
import { EUserEvents } from 'components/User/UserEvents'
import { RootStoreContext } from 'contexts'
import { GET_COACH_EVENTS } from 'graphql/GET_COACH_EVENTS'
import { GET_TEAMS_BY_COACH_ID } from 'graphql/GET_TEAMS_BY_COACH_ID'
import { INSERT_TEAMS_TO_COACH_MUTATION } from 'graphql/INSERT_TEAMS_TO_COACH_MUTATION'
import { clone } from 'mobx-state-tree'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Button, Card, Dimmer, Grid, Text } from 'tabler-react'
import { Types } from 'types/graphql'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'

const CoachDetails = () => {
  const { coach } = useContext(RootStoreContext)
  const { isAdmin } = useGetCurrentUserType()

  const coachClone = clone(coach)
  const [display, setDisplay] = React.useState('settings')
  return (
    <Grid.Row>
      <Grid.Col lg={4} sm={12} xs={12}>
        <UserCard
          firstName={coach?.first_name}
          lastName={coach?.last_name}
          profileAvatar={coach?.profile_avatar}
          profilePicture={coach?.profile_picture}
          source="admin"
        />
        <CoachContactInfo coach={coach} coachClone={coachClone} />
      </Grid.Col>
      <Grid.Col lg={8} sm={12} xs={12}>
        {isAdmin && (
        <Grid.Row className="mb-2">
          <Grid.Col>
            <Button.List className="float-right">
              <Button
                color={`${display === 'activity' ? 'primary' : 'white'}`}
                onClick={() => setDisplay('activity')}
              >
                Activity
              </Button>
              <Button
                color="white"
                //onClick={() => setDisplay('profile')}
                disabled
              >
                Profile
              </Button>
              <Button
                color="white"
                //onClick={() => setDisplay('sessions')}
                disabled
              >
                Sessions
              </Button>
              <Button
                color={`${display === 'settings' ? 'primary' : 'white'}`}
                onClick={() => setDisplay('settings')}
              >
                Settings
              </Button>
            </Button.List>
          </Grid.Col>
        </Grid.Row>
        )}
        {display === 'settings' && (
          <>
            <Grid.Row>
              <Grid.Col>
                <EditProfileForm user={coach} />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <CoachTeamsForm coachId={coach.id} />
              </Grid.Col>
            </Grid.Row>
          </>
        )}
        {display === 'activity' && (
          <Grid.Row>
            <Grid.Col>
              <CoachEvents coachId={coach.id} />
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid.Col>
    </Grid.Row>
  )
}

const CoachTeamsForm = ({ coachId }) => {
  const [selectedTeams, setSelectedTeams] = useState([])
  const [teams, setTeams] = useState([])
  const [teamUpdated, setTeamUpdated] = useState(false)

  const [insertTeamsToCoach] = useMutation(INSERT_TEAMS_TO_COACH_MUTATION, {
    onCompleted: () => {
      setTeamUpdated(false)
      toast.success('Teams updated successfully')
    }
  })

  const { loading, error, data } = useQuery(GET_TEAMS_BY_COACH_ID, {
    variables: {
      coachId: coachId,
      fetch: 'all'
    },
    fetchPolicy: 'network-only'
  })

  const changeTeamHandle = (options: Types.CoachTeam[]) => {
    setTeamUpdated(true)
    if (options) {
      const selected: number[] = options.map((team: Types.CoachTeam) => {
        return team.id
      })

      setSelectedTeams(selected)

      setTeams(
        teams.map((team: Types.CoachTeam) => {
          const teamClone = { ...team }
          if (selected.includes(team.id)) {
            teamClone.is_active = true
          } else {
            teamClone.is_active = false
          }
          return teamClone
        })
      )
    } else {
      setTeams(
        teams.filter((team: Types.CoachTeam) => {
          const teamClone = { ...team }
          teamClone.is_active = false
          return teamClone
        })
      )
    }
  }

  const handleSubmit = async (e) => {
    if (teamUpdated) {
      await insertTeamsToCoach({
        variables: {
          coach_id: coachId,
          teams: selectedTeams
        }
      })
    }
  }

  if (!loading && data && teams.length !== data.getTeamsByCoachId.length) {
    setTeams([...data.getTeamsByCoachId])
  }

  return (
    <Dimmer loader={loading} active={loading}>
      <Card statusColor="gray-dark">
        <Card.Header>
          <Card.Title>Teams</Card.Title>
        </Card.Header>
        <Card.Body>
          <Select
            name="teams"
            isMulti
            getOptionLabel={(option: Types.CoachTeam) => option.name}
            getOptionValue={(option: Types.CoachTeam) => option.id}
            options={teams}
            onChange={changeTeamHandle}
            value={teams.filter(
              (team: Types.CoachTeam) => team.is_active === true
            )}
          />
        </Card.Body>
        <Card.Footer className="text-right">
          <Button
            pill
            className="btn btn-gray-dark"
            disabled={!teamUpdated}
            type="submit"
            size="sm"
            onClick={handleSubmit}
          >
            UPDAT{'E'}
          </Button>
        </Card.Footer>
      </Card>
    </Dimmer>
  )
}

export const CoachEvents = ({ coachId }) => {
  const { loading, error, data } = useQuery(GET_COACH_EVENTS, {
    variables: {
      limit: 0,
      page: 0,
      filter: {
        coachId
      }
    }
  })
  return (
    <Card>
      <Card.Header>
        <Card.Title>Events</Card.Title>
      </Card.Header>
      <Card.Body>
        {loading && <div>Loading...</div>}
        {error && <div>Error</div>}
        {data && !data.getAllUserEvents && <div>No events found</div>}
        {data &&
          data.getAllUserEvents.userEvents.map((event) => (
            <CoachEvent key={event.user_events_id} event={event} />
          ))}
      </Card.Body>
    </Card>
  )
}

const CoachEvent = ({ event }) => {
  const {
    camp_id,
    camp_name,
    created_on,
    first_name,
    last_name,
    slug,
    status,
    student_id,
    user_duration,
    user_registration_id,
    invoice_id
  } = event

  return (
    <>
      <Grid.Row className="mb-3">
        <Grid.Col lg={8}>
          {EUserEvents[slug]}
          <strong>{user_registration_id && ' ' + status} </strong>
          for{' '}
          <strong>
            {first_name && last_name ? `${first_name} ${last_name}` : status}
          </strong>
          {invoice_id && (
            <>
              {' '}
              -{' '}
              <Link to={`/user/invoices/${invoice_id}`}>
                Invoice {invoice_id}
              </Link>
            </>
          )}
          {camp_id && (
            <>
              {user_duration && (
                <>
                  {' '}
                  - <strong>{user_duration} minutes</strong>
                </>
              )}{' '}
              <Link to={`/user/calendar/${camp_id}`}>{camp_name}</Link>
            </>
          )}
          <div>
            <small>
              <i>
                <Moment fromNow>{moment(created_on).format()}</Moment>
              </i>
            </small>
          </div>
        </Grid.Col>
        <Grid.Col lg={4}>
          <Text className="ml-auto float-right">
            {moment(created_on).format('M/D/YY - h:mma')}
          </Text>
        </Grid.Col>
      </Grid.Row>
    </>
  )
}

export default CoachDetails
