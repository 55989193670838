import gql from 'graphql-tag'

export const GET_COACH_EMAIL_VALIDATION = gql`
  query GET_COACH_EMAIL_VALIDATION($email: String!) {
    validateCoachEmail(email: $email) {
      id
      first_name
      last_name
      email
      phone_number
      phone_country_code
    }
  }
`
