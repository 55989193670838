import gql from 'graphql-tag'

export const GET_COACH_PHONE_VALIDATION = gql`
  query GET_COACH_PHONE_VALIDATION($phone: String!) {
    validateCoachPhone(phone: $phone) {
      id
      first_name
      last_name
      email
      phone_number
      phone_country_code
    }
  }
`
