export const STATUS_ICON_COLOR_MAPPING = new Map([
  [
    'Confirmed',
    { icon: 'check-circle', color: 'success', className: 'text-success' }
  ],
  [
    'Invite Confirmed',
    { icon: 'check-circle', color: 'success', className: 'text-success' }
  ],
  [
    'Pending',
    { icon: 'alert-circle', color: 'info', className: 'text-info' }
  ],
  [
    'Waitlist',
    { icon: 'pause-circle', color: 'info', className: 'text-info' }
  ],
  [
    'Conditionally Approved',
    { icon: 'check-circle', color: 'info', className: 'text-info' }
  ],
  [
    'Invite Ready',
    { icon: 'check-circle', color: 'info', className: 'text-info' }
  ],
  [
    'Conditionally Approved',
    { icon: 'check-circle', color: 'info', className: 'text-info' }
  ],
  [
    'Rejected',
    { icon: 'stop-circle', color: 'danger', className: 'text-danger' }
  ],
  [
    'Withdrawn',
    { icon: 'x-circle', color: 'danger', className: 'text-danger' }
  ],
  [
    'Accepted',
    { icon: 'check-circle', color: 'info', className: 'text-info' }
  ],
  [
    'Phase 1 approved',
    { icon: 'alert-circle', color: 'secondary', className: 'text-secondary' }
  ],
  [
    'Phase 2 approved',
    { icon: 'alert-circle', color: 'info', className: 'text-info' }
  ]
])
