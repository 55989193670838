import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import AccountBalanceCard from 'components/User/Profile/AccountBalanceCard'
import { ADD_STUDENT_TO_DASHBOARD } from 'graphql/ADD_STUDENT_TO_DASHBOARD'
import { GET_STUDENT_ON_DASHBOARD_BY_IDS } from 'graphql/GET_STUDENT_ON_DASHBOARD_BY_IDS'
import { GET_STUDENTS_ON_DASHBOARD } from 'graphql/GET_STUDENTS_ON_DASHBOARD'
import { REMOVE_STUDENT_FROM_DASHBOARD } from 'graphql/REMOVE_STUDENT_FROM_DASHBOARD'
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate'
import UserRoleList from 'modules/user/roles/components/list/UserRoleList'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Card, Grid, Icon, Text } from 'tabler-react'
import { LoadStoreProvider } from '../../contexts/LoadStoreContext'

import { UserStoreContext } from '../../contexts/userStoreContext'
import { ADD_STUDENT_TO_TEAM } from '../../graphql/ADD_STUDENT_TO_TEAM'
import { GET_ACCOUNT_BALANCE } from '../../graphql/GET_ACCOUNT_BALANCE'
import { GET_SPORTS_LOCATIONS_TEAMS_PROGRESSIONS_BY_STUDENT_ID } from '../../graphql/GET_SPORTS_LOCATIONS_TEAMS_PROGRESSIONS_BY_STUDENT_ID'
import { GET_STUDENT_CAMPS } from '../../graphql/GET_STUDENT_CAMPS'
import { GET_STUDENT_TEAM } from '../../graphql/GET_STUDENT_TEAM'
import { GET_ACCOUNT_BALANCE as GET_ACCOUNT_BALANCE_TYPE } from '../../graphql/types/GET_ACCOUNT_BALANCE'
import { useRootStore } from '../../hooks/useRootStore'
import useGetCurrentUserType, {
  useCoachTeam
} from '../../modules/common/hooks/useGetCurrentUserType'
import { formatHeight } from '../../utils/numberFormat'
import AccountsInvoice from '../Payments/AccountsInvoice'
import ReservelogCard from '../ReserveLogs/ReservelogCard'
import CoachStudentToggle from '../Students/Forms/CoachStudentToggle'
import AddStudentToLoadModal from '../Students/Modal/AddStudentToLoadModal'
import StudentCamps from '../Students/StudentCamps'
import StudentNotesList from '../Students/StudentNotesList'
import StudentTeamProgressions from '../Students/StudentTeamProgressions'
import DzJumpReady from './DZM/DzJumpReady'
import AddStudentNoteForm from './Forms/AddStudentNoteForm'
import DocumentForm from './Forms/DocumentForm'
import UserCredentialsForm from './Forms/UserCredentialsForm'
import UserProfileForm from './Forms/UserProfileForm'
import MeritCard from './Profile/MeritCard'
import UserCard from './Profile/UserCard'
import SkycruAccountDetails from './SkycruAccountDetails'
import UserActivitiesList from './UserActivitiesList'
import UserCards from './UserCards'
import UserCurrencyCard from './UserCurrencyCard'
import UserDZMCheckInAndOut from './UserDZMCheckInAndOut'
import UserEmergencyContact from './UserEmergencyContact'
import UserLocationList from './UserLocationList'
import UserTeamsList from './UserTeamsList'
import { EUserTypes } from './userTypes'
// import UserTunnelSkills from './UserTunnelSkills';
import { EHostnames } from 'components/constants'
import UserVerify from './UserVerify'

const defaultCardList = {
  showReservelog: false,
  showCurrency: false,
  showUSPA: false,
  showWaiver: false,
  showGears: false,
  showGearAndReserve: false
}

const UserDetails = () => {
  const { user } = useContext(UserStoreContext)
  const rootStore = useRootStore()
  const { currentUser, currentCoachTeam } = rootStore
  const { isAdmin, isDzm } = useGetCurrentUserType()
  const { domainData } = rootStore

  const { team } = useCoachTeam({ teamId: currentCoachTeam?.id })

  const [isOnDeck, setIsOnDeck] = useState(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false)

  const [showCard, setShowCard] = useState(defaultCardList)

  const toggleCardDisplay = (cardName: string) => {
    const cardList = { ...defaultCardList }
    cardList[cardName] = !showCard[cardName]
    setShowCard(cardList)
  }

  const [addStudentToTeam] = useMutation(ADD_STUDENT_TO_TEAM)
  const [
    getStudentTeam,
    { data: studentTeamData, loading: studentTeamLoading }
  ] = useLazyQuery(GET_STUDENT_TEAM)

  useEffect(() => {
    if (currentCoachTeam?.id)
      getStudentTeam({
        variables: {
          student_id: Number(user?.id),
          team_id: currentCoachTeam?.id
        }
      })
  }, [user?.id, currentCoachTeam?.id])

  const hasAuthMerits = user?.auth_merits.length > 0

  const { error: sportsLocationsError, data: sportsLocationsData } = useQuery(
    GET_SPORTS_LOCATIONS_TEAMS_PROGRESSIONS_BY_STUDENT_ID,
    {
      variables: { student_id: user?.id }
    }
  )

  const [removeStudentFromDashboard] = useMutation(
    REMOVE_STUDENT_FROM_DASHBOARD
  )

  const [addStudentToDashboard] = useMutation(ADD_STUDENT_TO_DASHBOARD)

  const { loading: dashboardStudentDataLoading, data: dashboardStudentData } =
    useQuery(GET_STUDENT_ON_DASHBOARD_BY_IDS, {
      variables: {
        team_id: currentCoachTeam?.id,
        student_id: user?.id
      },
      skip: !currentCoachTeam
    })

  const { data: accountBalance } = useQuery<GET_ACCOUNT_BALANCE_TYPE>(
    GET_ACCOUNT_BALANCE,
    {
      variables: {
        studentId: user?.id,
        teamId: currentCoachTeam?.id ?? 0
      }
    }
  )

  const { data: studentCamps, loading: campsLoading } = useQuery(
    GET_STUDENT_CAMPS,
    {
      variables: {
        getStudentCampsInput: {
          studentId: Number(user?.id)
        }
      }
    }
  )

  useEffect(() => {
    if (!dashboardStudentDataLoading && dashboardStudentData) {
      setIsOnDeck(dashboardStudentData.getStudentOnDashboardByIds)
    }
  }, [dashboardStudentDataLoading, dashboardStudentData])

  if (sportsLocationsError) {
    return <p>{`Error: ${sportsLocationsError.message}`}</p>
  }

  const toggleEdit = () => {
    setIsEditing(!isEditing)
  }

  const getAge = (dateString) => {
    const today = new Date()
    const birthDate = new Date(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  const toggleStudentOnDeck = async () => {
    if (isOnDeck) {
      await removeStudentFromDashboard({
        variables: {
          team_id: currentCoachTeam.id,
          student_id: user.id
        },
        refetchQueries: [
          {
            query: GET_STUDENTS_ON_DASHBOARD,
            variables: {
              team_id: currentCoachTeam.id
            }
          },
          {
            query: GET_STUDENT_ON_DASHBOARD_BY_IDS,
            variables: {
              team_id: currentCoachTeam.id,
              student_id: user.id
            }
          }
        ]
      })
      toast.success(
        `${user?.first_name} ${user?.last_name} ${
          isDzm ? 'is checked out.' : 'removed from dashboard.'
        }`
      )
    } else {
      await addStudentToDashboard({
        variables: {
          team_id: currentCoachTeam.id,
          student_id: user.id
        },
        refetchQueries: [
          {
            query: GET_STUDENTS_ON_DASHBOARD,
            variables: {
              team_id: currentCoachTeam.id
            }
          },
          {
            query: GET_STUDENT_ON_DASHBOARD_BY_IDS,
            variables: {
              team_id: currentCoachTeam.id,
              student_id: user.id
            }
          }
        ]
      })
      toast.success(
        `${user?.first_name} ${user?.last_name} ${
          isDzm ? 'is checked in.' : 'added to dashboard.'
        }`
      )
    }
  }

  return (
    <Grid.Row>
      <Grid.Col lg={4} sm={12} xs={12}>
        <UserCard
          firstName={user?.first_name}
          lastName={user?.last_name}
          profileAvatar={user?.profile_avatar}
          profilePicture={user?.profile_picture}
        />

        {currentCoachTeam &&
          !isAdmin &&
          !studentTeamData?.getStudentTeam &&
          !studentTeamLoading && (
            <Grid.Col className="text-center p-0">
              <Button
                icon="plus-circle"
                block
                color="secondary"
                type="submit"
                onClick={() => {
                  if (
                    window.confirm(
                      'Adding connection: ' +
                        user?.first_name +
                        ' ' +
                        user?.last_name +
                        ' to ' +
                        currentCoachTeam.name +
                        '. Continue?'
                    )
                  ) {
                    addStudentToTeam({
                      variables: {
                        student_id: Number(user.id),
                        team_id: currentCoachTeam?.id
                      },
                      refetchQueries: [
                        {
                          query: GET_STUDENT_TEAM,
                          variables: {
                            student_id: Number(user.id),
                            team_id: currentCoachTeam?.id
                          }
                        }
                      ]
                    })
                  }
                }}
              >
                CONNECT
              </Button>
            </Grid.Col>
          )}

        {(currentUser.type === EUserTypes.admin ||
          studentTeamData?.getStudentTeam) &&
          !studentTeamLoading && (
            <>
              <MeritCard />
              <SkycruAccountDetails />
              {currentUser.type !== EUserTypes.admin && (
                <>
                  <FeatureAccessGate feature="feature_accounting">
                    <AccountBalanceCard />
                  </FeatureAccessGate>
                  <FeatureAccessGate feature="feature_invoices">
                    <AccountsInvoice />
                  </FeatureAccessGate>
                  <FeatureAccessGate feature="feature_paid_status">
                    <AddStudentNoteForm />
                  </FeatureAccessGate>
                </>
              )}
              {/*
              {sportsLocationsData && currentUser.type === EUserTypes.admin && (
                <UserTunnelSkills
                  progressions={sportsLocationsData.getStudentProgressions}
                />
              )}
              */}
              {currentUser.type === EUserTypes.admin && sportsLocationsData && (
                <>
                  <UserActivitiesList />
                  <UserLocationList
                    locations={sportsLocationsData.getLocationsByStudentId}
                  />
                </>
              )}
              {currentUser.type === EUserTypes.dzm && (
                <UserEmergencyContact user={user} />
              )}
              <FeatureAccessGate feature="feature_camps">
                {!campsLoading && studentCamps?.getStudentCamps && (
                  <StudentCamps
                    studentId={Number(user.id)}
                    camps={studentCamps?.getStudentCamps}
                    loading={campsLoading}
                  />
                )}
              </FeatureAccessGate>
            </>
          )}
      </Grid.Col>
      {(currentUser.type === EUserTypes.admin ||
        studentTeamData?.getStudentTeam) &&
        !studentTeamLoading && (
          <Grid.Col lg={8} sm={12} xs={12}>
            <DzJumpReady
              accountBalance={accountBalance}
              toggleCardDisplay={toggleCardDisplay}
            />
            <Card statusColor="blue">
              <Card.Header className="d-block d-md-flex pt-3 pl-3">
                <Card.Title className="mb-3 mb-md-0">
                  <div className="d-flex align-items-center">
                    <div className="mr-3">
                      {!user?.registration_id ? (
                        <Icon
                          name="user-x"
                          className="mr-2 ml-0 text-secondary"
                        />
                      ) : (
                        <Icon
                          name="user-check"
                          className="mr-2 ml-0 text-success"
                        />
                      )}
                      {!user ? (
                        'ID Not Found'
                      ) : (
                        <Link
                          className="text-decoration-none"
                          to="#"
                          onClick={() => toggleEdit()}
                        >
                          {user.first_name} {user.last_name}{' '}
                          {user.preferred_name &&
                            ' (' + user.preferred_name + ')'}
                        </Link>
                      )}
                    </div>
                  </div>
                </Card.Title>
                <Card.Options>
                  <Button.List>
                    {currentUser.type === EUserTypes.coach && (
                      <FeatureAccessGate feature="feature_crm">
                        <CoachStudentToggle
                          student_id={Number(user.id)}
                          coach_id={currentUser.id}
                        />
                      </FeatureAccessGate>
                    )}
                    {currentUser.type !== EUserTypes.admin && (
                      <FeatureAccessGate feature="feature_dashboard">
                        <Button
                          className={
                            isOnDeck
                              ? 'btn-white text-primary'
                              : 'btn-white text-muted'
                          }
                          size="sm"
                          icon={'home'}
                          onClick={() => toggleStudentOnDeck()}
                          disabled={isAdmin}
                        >
                          {isOnDeck ? 'Checked In' : 'Checked Out'}
                        </Button>
                      </FeatureAccessGate>
                    )}
                    {currentUser.type === EUserTypes.dzm && (
                      <>
                        <FeatureAccessGate feature="feature_manifest">
                          <Button
                            className="text-secondary"
                            color="white"
                            icon="user-plus"
                            size="sm"
                            onClick={() => setIsLoadModalOpen(true)}
                            disabled={!isOnDeck}
                          >
                            LOAD
                          </Button>
                          <FeatureAccessGate feature="feature_alpha">
                            <UserDZMCheckInAndOut
                              currentCoachTeam={currentCoachTeam}
                              student={user}
                            />
                          </FeatureAccessGate>
                        </FeatureAccessGate>
                      </>
                    )}
                    <Button size="sm" color="white" disabled>
                      {domainData?.key !== EHostnames.SKYCRU
                        ? 'ID: '
                        : 'SKY.D: '}
                      <b>{user?.id}</b>
                    </Button>
                  </Button.List>
                </Card.Options>
              </Card.Header>
              {isEditing ? (
                <UserProfileForm />
              ) : (
                <>
                  <Card.Body>
                    <div
                      className="card-body-click"
                      onClick={() => toggleEdit()}
                    >
                      <Grid.Row>
                        <Grid.Col sm={6} md={6}>
                          {user.address1 + ' '}
                          {user.address2}
                        </Grid.Col>
                        <Grid.Col sm={6} md={6}></Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col sm={6} md={6}>
                          {user.city ? (
                            user.city + ', '
                          ) : (
                            <span className="text-warning"></span>
                          )}
                          {user.province} {user.postal_code}
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col sm={6} md={6}>
                          {user.country ? (
                            user.country
                          ) : (
                            <span className="text-warning"></span>
                          )}
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row className="mt-5">
                        <Grid.Col width={6} xs={6} sm={6} md={3} lg={3}>
                          {user.dob && (
                            <div>
                              Age <h4>{getAge(user.dob)}</h4>
                            </div>
                          )}
                        </Grid.Col>
                        <Grid.Col width={6} xs={6} sm={6} md={3} lg={3}>
                          {user.height !== 0 && (
                            <div>
                              Height{' '}
                              <h4>
                                {user.height ? formatHeight(user?.height) : ' '}
                              </h4>
                            </div>
                          )}
                        </Grid.Col>
                        <Grid.Col width={6} xs={6} sm={6} md={3} lg={3}>
                          {user.weight !== 0 && (
                            <div>
                              Weight{' '}
                              <h4>
                                {user.weight ? user.weight : ' '}
                                {' lbs'}
                              </h4>
                            </div>
                          )}
                        </Grid.Col>
                        {user.jump_weight !== 0 &&
                          [
                            EUserTypes.dzm.toString(),
                            EUserTypes.rigger.toString(),
                            EUserTypes.admin.toString()
                          ].includes(currentUser.type) && (
                            <Grid.Col width={6} xs={6} sm={6} md={3} lg={3}>
                              Exit Weight{' '}
                              <h4>
                                {user.jump_weight ? (
                                  user.jump_weight + ' lbs'
                                ) : user.weight ? (
                                  <Text className="font-italic">
                                    {user.weight + 25} lbs (est)
                                  </Text>
                                ) : (
                                  ' '
                                )}
                              </h4>
                            </Grid.Col>
                          )}
                      </Grid.Row>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <div className="float-left">
                      <UserVerify />
                    </div>
                    <UserRoleList />
                  </Card.Footer>
                </>
              )}
            </Card>
            {currentUser.type === EUserTypes.admin && sportsLocationsData && (
              <UserTeamsList teams={sportsLocationsData.getTeamsByStudentId} />
            )}
            {currentUser.type !== EUserTypes.coach && (
              <>
                {showCard['showCurrency'] &&
                  domainData.key === EHostnames.SKYCRU && (
                    <>
                      <UserCurrencyCard />
                    </>
                  )}
              </>
            )}
            {showCard['showReservelog'] && <ReservelogCard />}
            {showCard['showGears'] && <UserCards />}
            {showCard['showGearAndReserve'] && (
              <>
                <ReservelogCard />
                <UserCards />
              </>
            )}
            {hasAuthMerits && ''}
            {showCard['showUSPA'] && <UserCredentialsForm />}
            {(currentUser.type === EUserTypes.dzm ||
              currentUser.type === EUserTypes.wtm) &&
              showCard['showWaiver'] && (
                <DocumentForm
                  currentCoachTeam={currentCoachTeam}
                  student={user}
                />
              )}
            <FeatureAccessGate feature="feature_student_progression">
              <StudentTeamProgressions
                studentId={Number(user.id)}
                teamId={currentCoachTeam?.id}
                isModal={false}
              />
            </FeatureAccessGate>
            {currentUser.type !== EUserTypes.admin && (
              <StudentNotesList studentId={Number(user.id)} />
            )}
          </Grid.Col>
        )}
      <LoadStoreProvider>
        <AddStudentToLoadModal
          studentId={Number(user?.id)}
          toggleModal={() => setIsLoadModalOpen(false)}
          isOpen={isLoadModalOpen}
        />
      </LoadStoreProvider>
    </Grid.Row>
  )
}

export default UserDetails
