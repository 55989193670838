import gql from 'graphql-tag'

export const ADMIN_HOME_QUERY = gql`
  query ADMIN_HOME_QUERY(
    $coachLimit: Int
    $coachOrder: String
    $studentOrder: OrderOptions
    $studentFilter: StudentFilter
    $registeredStudentFilter: String
    $registeredStudentLimit: Int
    $registeredStudentOrder: String
  ) {
    getPaymentsTotal
    getPaymentsProcessedTotal
    getRegistrationsTotal
    getCampsTotal
    getStudentsTotal {
      totalStudent
      registeredStudent
    }
    getMeritsTotal {
      auth_merits_total
      user_merits_total
    }
    getCoaches(limit: $coachLimit, order: $coachOrder) {
      id
      first_name
      last_name
      email
      phone_number
      is_disabled_on
      registration_id
      email_private
      phone_private
      profile_avatar
      profile_picture
    }
    students(order: $studentOrder, filter: $studentFilter) {
      address1
      address2
      city
      country
      dob
      email
      first_name
      id
      jump_weight
      last_name
      phone_number
      phone_country_code
      postal_code
      profile_avatar
      province
      registration_id
      weight

      uspa {
        id
        email
        first_name
        last_name
        merits_uspa_member_id
        merits_uspa_member_expiration
        template_id
        template_title
        updated_on
        user_id
        user_type
      }
    }
    getRegisteredStudents(
      filter: $registeredStudentFilter
      limit: $registeredStudentLimit
      order: $registeredStudentOrder
    ) {
      student_id
      address1
      address2
      camp_id
      camp_name
      city
      country
      dob
      email
      first_name
      id
      jump_weight
      last_name
      phone_country_code
      phone_number
      postal_code
      profile_avatar
      province
      registration_id
      user_registrations_id
      weight
      status
    }
  }
`
