import React from 'react'
import Modal from '../../Modal'
import InvoiceDetails from '../InvoiceDetails'

interface InvoiceDetailsModalProps {
  invoiceId: number
  isOpen: boolean
  closeModal: (b: boolean) => void
}

const InvoiceDetailsModal = ({
  invoiceId,
  closeModal,
  isOpen
}: InvoiceDetailsModalProps) => {
  return (
    <Modal
      maxWidth={'lg'}
      title={`Invoice #${invoiceId}`}
      open={isOpen}
      onClose={() => closeModal(false)}
      content={<InvoiceDetails invoiceId={invoiceId} />}
    />
  )
}

export default InvoiceDetailsModal
