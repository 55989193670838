import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { EUserFilterOptions } from 'components/enum'
import Downshift from 'downshift'
import { Formik } from 'formik'
import { ACCOUNT_BALANCE_TRANSACTION } from 'graphql/ACCOUNT_BALANCE_TRANSACTION'
import { GET_ACCOUNT_BALANCE } from 'graphql/GET_ACCOUNT_BALANCE'
import { GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY } from 'graphql/GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY'
import { GET_STUDENT_TIME_TRANSACTION_HISTORY } from 'graphql/GET_STUDENT_TIME_TRANSACTION_HISTORY'
import { GET_STUDENT_TRANSACTIONS_BY_DATE } from 'graphql/GET_STUDENT_TRANSACTIONS_BY_DATE'
import { GET_STUDENTS_BY_FILTER_QUERY } from 'graphql/GET_STUDENTS_BY_FILTER_QUERY'
import { GET_STUDENTS_ON_DASHBOARD } from 'graphql/GET_STUDENTS_ON_DASHBOARD'
import { GET_ACCOUNT_BALANCE as GET_ACCOUNT_BALANCE_TYPE } from 'graphql/types/GET_ACCOUNT_BALANCE'
import { EOrderOptions, IArgsOptions } from 'hooks/useAutocomplete'
import { useRootStore } from 'hooks/useRootStore'
import debounce from 'lodash.debounce'
import { FC, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Form, Text } from 'tabler-react'
import { formatAccountBalance } from 'utils/formatAccountBalance'
import { capitalize } from 'utils/stringFormat'
import * as Yup from 'yup'
import { GET_ACCOUNT_TYPE_BY_TEAM_ID } from '../../../graphql/GET_ACCOUNT_TYPE_BY_TEAM_ID'
import { GET_ACCOUNT_TYPE_BY_TEAM_ID as GET_ACCOUNT_TYPE_BY_TEAM_ID_TYPE } from '../../../graphql/types/GET_ACCOUNT_TYPE_BY_TEAM_ID'
import { parseIntNullable } from '../../../utils/numberParser'

export const TransactionType = {
  CREDIT: 'Credit',
  REMOVE: 'Remove',
  TRANSFER: 'Transfer'
}

export const TransactionTypeId = {
  CREDIT: 5,
  REMOVE: 2,
  TRANSFER: 3
}

export const TransactionColor = {
  CREDIT: 'primary',
  REMOVE: 'secondary',
  TRANSFER: 'warning'
}

export const TransactionIcon = {
  CREDIT: 'plus',
  REMOVE: 'minus',
  TRANSFER: 'arrow-right'
}

const SEARCH_LIMIT = 15

interface AccountBalanceFormProps {
  studentBalance: string | number
  studentName: string
  handleSetAccountBalanceFormShown: (value: boolean) => void
  teamId: number
  userId: number
  transactionType?: keyof typeof TransactionType
  accountTypeSlug?: string
}

const AccountBalanceForm: FC<AccountBalanceFormProps> = ({
  studentBalance,
  studentName,
  handleSetAccountBalanceFormShown,
  teamId,
  userId,
  transactionType,
  accountTypeSlug
}) => {
  const { currentUser } = useRootStore()

  const { data: accountTypeData, loading: accountTypeLoading } =
    useQuery<GET_ACCOUNT_TYPE_BY_TEAM_ID_TYPE>(GET_ACCOUNT_TYPE_BY_TEAM_ID, {
      variables: {
        teamId
      }
    })

  const accountType = useMemo(() => {
    if (accountTypeData?.getAccountTypeByTeamId) {
      return accountTypeData.getAccountTypeByTeamId
    }
    return null
  }, [accountTypeData?.getAccountTypeByTeamId, accountTypeLoading])

  const defaultTransactionType = transactionType
  const [transferInputClass, setTransferInputClass] = useState('primary')

  const [isAccountBalanceNotesOpen, setIsAccountBalanceNotesOpen] =
    useState(false)
  const [isTransferToModalOpen, setIsTransferToModalOpen] = useState(false)
  const [transferToId, setTransferToId] = useState<string | null>(null)
  const [transferToName, setTransferToName] = useState<string | null>(null)

  const searchRef = useRef('')

  const argsOptions: IArgsOptions = {
    order: EOrderOptions.alphabet,
    filter: {
      team_id: teamId.toString(),
      filter_by: EUserFilterOptions.enabled
    }
  }

  const [students, { loading, data }] = useLazyQuery(
    GET_STUDENTS_BY_FILTER_QUERY
  )

  const [
    accountBalance,
    { loading: accountBalanceLoading, data: accountBalanceData }
  ] = useLazyQuery<GET_ACCOUNT_BALANCE_TYPE>(GET_ACCOUNT_BALANCE)

  const balance = useMemo(() => {
    if (!accountBalanceData?.getAccountBalance) {
      return null
    }

    return formatAccountBalance(
      accountBalanceData?.getAccountBalance.total_time,
      accountBalanceData?.getAccountBalance.account_type_slug
    )
  }, [accountBalanceData?.getAccountBalance, accountBalanceLoading])

  const filteredStudents = useMemo(() => {
    if (!data) {
      return []
    }

    return data[Object.keys(data)[0]]
  }, [data?.students])

  const toggleAccountBalanceNotes = () =>
    setIsAccountBalanceNotesOpen(!isAccountBalanceNotesOpen)
  const toggleTransferToModal = () =>
    setIsTransferToModalOpen(!isTransferToModalOpen)

  const [accountBalanceTransaction] = useMutation(ACCOUNT_BALANCE_TRANSACTION)

  const handleInputTransferToStudent = debounce(async (inputValue: string) => {
    students({
      variables: {
        ...argsOptions,
        filter: {
          ...argsOptions.filter,
          limit: SEARCH_LIMIT,
          search: inputValue
        }
      }
    })
  }, 360)

  const renderForm = ({
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    values
  }) => (
    <form>
      <div className="">
        {transactionType !== TransactionType.TRANSFER.toUpperCase() && (
          <>
            <Form.Group
              label={`Amount to ${capitalize(
                defaultTransactionType.toLowerCase()
              )}:`}
            />
            <Form.Group className={'text-center'}>
              <>
                <Form.Group>
                  {accountType?.slug === 'time' ? (
                    <Form.MaskedInput
                      autoFocus
                      className={'text-center text-xxl form-control'}
                      placeholder="00:00"
                      mask={[
                        `${transactionType === 'CREDIT' ? '+' : '-'}`,
                        /\d/,
                        /\d/,
                        ':',
                        /\d/,
                        /\d/
                      ]}
                      name="rawValue"
                      onChange={(e) => {
                        const inputArray = e.target.value.split(':')
                        const inputTime =
                          parseIntNullable(inputArray[0].replace(/\D/g, '')) *
                            60 +
                          parseIntNullable(inputArray[1])
                        setFieldValue('minutes', inputTime)
                        setFieldValue('rawValue', e.target.value)
                      }}
                      key={defaultTransactionType}
                    />
                  ) : (
                    <Form.Input
                      autoFocus
                      className={'text-center text-xxl'}
                      placeholder=""
                      key={defaultTransactionType}
                      name="rawValue"
                      type="number"
                      onChange={(e) => {
                        setFieldValue('minutes', Number(e.target.value))
                        setFieldValue('rawValue', e.target.value)
                      }}
                    />
                  )}
                </Form.Group>{' '}
              </>
              {/*<text className="text-primary font-weight-bold mx-auto text-xxl ">*/}
              {/*  {formatAccountBalance(values.minutes, accountType?.slug)}*/}
              {/*</text>*/}
            </Form.Group>

            <Form.Group>
              <Form.Textarea
                name="notes"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Add notes (optional)"
                rows={3}
                type="text"
                value={values.notes}
              />
            </Form.Group>
            <Button.List className="mt-4" align="right">
              <Button
                disabled={isSubmitting || values.minutes === 0}
                loading={isSubmitting}
                onClick={handleSubmit}
                block
                color={TransactionColor[defaultTransactionType]}
                type="submit"
              >
                {defaultTransactionType}
              </Button>
            </Button.List>
          </>
        )}

        {transactionType === TransactionType.TRANSFER.toUpperCase() && (
          <>
            <Form.Group
              label={`Amount to ${capitalize(
                defaultTransactionType.toLowerCase()
              )}:`}
            />
            <Form.Group className={'text-center'}>
              <>
                <Form.Group>
                  {accountType?.slug === 'time' ? (
                    <Form.MaskedInput
                      autoFocus
                      className={'text-center text-xxl form-control'}
                      placeholder="00:00"
                      mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                      name="rawValue"
                      onChange={(e) => {
                        const inputArray = e.target.value.split(':')
                        const inputTime =
                          parseIntNullable(inputArray[0]) * 60 +
                          parseIntNullable(inputArray[1])
                        setFieldValue('minutes', inputTime)
                        setFieldValue('rawValue', e.target.value)

                        if (inputTime > Number(studentBalance)) {
                          setTransferInputClass('danger')
                        } else {
                          setTransferInputClass('primary')
                        }
                      }}
                      key={defaultTransactionType}
                    />
                  ) : (
                    <Form.Input
                      autoFocus
                      className={'text-center text-xxl'}
                      placeholder=""
                      key={defaultTransactionType}
                      name="rawValue"
                      type="number"
                      onChange={(e) => {
                        setFieldValue('minutes', Number(e.target.value))
                        setFieldValue('rawValue', e.target.value)
                      }}
                    />
                  )}
                </Form.Group>{' '}
              </>
              {/*<text className="text-primary font-weight-bold mx-auto text-xxl ">*/}
              {/*  {formatAccountBalance(values.minutes, accountType?.slug)}*/}
              {/*</text>*/}
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="search">Transfer from:</Form.Label>
              <Text>
                {studentName} (
                {formatAccountBalance(Number(studentBalance), accountTypeSlug)})
              </Text>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="search">Transfer to:</Form.Label>
              <div className="search-container w-150">
                <Downshift
                  onChange={(selectedItem) => {
                    setTransferToId(selectedItem.id)
                    setTransferToName(
                      `${selectedItem.first_name} ${selectedItem.last_name}`
                    )
                    searchRef.current = `${selectedItem.first_name} ${selectedItem.last_name}`

                    accountBalance({
                      variables: {
                        studentId: selectedItem.id,
                        teamId
                      }
                    })
                  }}
                >
                  {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    inputValue,
                    highlightedIndex
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          className: 'form-control cursor-pointer',
                          id: 'search',
                          name: 'transferTo',
                          placeholder: 'Search by name...',
                          type: 'search',
                          value: searchRef.current,
                          onChange: (e) => {
                            searchRef.current = e.target.value
                            e.persist()
                            handleInputTransferToStudent(e.target.value)
                          }
                        })}
                      />
                      {isOpen && (
                        <div className="search-container-dropdown">
                          {filteredStudents.map((item, idx) => (
                            <div
                              {...getItemProps({ item })}
                              className={`search-container-dropdown-item ${
                                idx === highlightedIndex &&
                                'search-container-dropdown-item-highlighted'
                              }`}
                              key={item.id}
                            >
                              <span>
                                {`${item.first_name} ${item.last_name}`}
                              </span>
                              <span className="small text-primary ml-1">
                                {item.id}
                              </span>
                            </div>
                          ))}
                          {!filteredStudents.length && !loading && (
                            <div className="search-container-dropdown-item">
                              No results for {inputValue}.
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </Downshift>
                {transferToId && balance && (
                  <Text className="mt-2">
                    {transferToName} {balance}
                  </Text>
                )}
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Textarea
                name="notes"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Add notes (optional)"
                rows={3}
                type="text"
                value={values.notes}
              />
            </Form.Group>
            <Button.List className="mt-4" align="right">
              <Button
                disabled={isSubmitting || !transferToId || values.minutes === 0}
                loading={isSubmitting}
                onClick={handleSubmit}
                block
                color={TransactionColor[defaultTransactionType]}
                type="submit"
              >
                TRANSFER
              </Button>
            </Button.List>
          </>
        )}
      </div>
    </form>
  )

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        minutes: Yup.number().required('Required'),
        notes: Yup.string().nullable()
      })}
      initialValues={{
        rawValue: '',
        minutes: 0,
        notes: ''
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        const { minutes, notes } = values

        try {
          await accountBalanceTransaction({
            variables: {
              payload: {
                account_type_id: accountType?.id,
                created_by: currentUser.id,
                minutes:
                  defaultTransactionType === 'CREDIT' ? minutes : -minutes,
                notes,
                student_id: userId,
                team_id: teamId,
                transfer_to: transferToId,
                transaction_type_id: TransactionTypeId[defaultTransactionType]
              }
            },
            refetchQueries: [
              {
                query: GET_ACCOUNT_BALANCE,
                variables: {
                  studentId: Number(userId),
                  teamId
                }
              },
              {
                query: GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY,
                variables: {
                  studentId: userId.toString(),
                  teamId,
                  userType: currentUser.type
                }
              },
              {
                query: GET_STUDENT_TIME_TRANSACTION_HISTORY,
                variables: {
                  studentId: Number(userId),
                  teamId,
                  userType: currentUser.type
                }
              },
              {
                query: GET_STUDENTS_ON_DASHBOARD,
                variables: {
                  team_id: teamId
                }
              },
              {
                query: GET_STUDENT_TRANSACTIONS_BY_DATE,
                variables: {
                  studentId: userId.toString(),
                  teamId,
                  date: new Date().toISOString().split('T')[0]
                }
              }
            ]
          })
        } catch (error) {
          toast.error(error)
        }

        setSubmitting(false)
        handleSetAccountBalanceFormShown(false)

        toast.success('Transaction saved')

        resetForm()
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  )
}

export default AccountBalanceForm
