import { EHostnames } from 'components/constants'
import { EUserTypes } from 'components/User/userTypes'
import { useRootStore } from 'hooks/useRootStore'
import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Grid, List, Text } from 'tabler-react'

const FooterNav = () => {
  const rootStore = useRootStore()
  const { domainData } = rootStore

  const { currentUser } = rootStore
  return (
    <div className="footer">
      <Container>
        <Grid.Row>
          <Grid.Col lg={8} sm={12} xs={12} className="grid-col mb-sm-4">
            <Grid.Row>
              <React.Fragment>
                {[EUserTypes.dzm.toString()].includes(currentUser?.type) && (
                  <>
                    <Grid.Col className="grid-col" lg={4} sm={12} xs={12}>
                      <List unstyled={true} className="mb-0">
                        <li>
                          <Link to="/dzm/pricing">Upgrade Plan</Link>
                        </li>
                      </List>
                    </Grid.Col>
                  </>
                )}
                {[EUserTypes.rigger.toString()].includes(currentUser?.type) && (
                  <>
                    <Grid.Col className="grid-col" lg={4} sm={12} xs={12}>
                      <List unstyled={true} className="mb-0">
                        <li>
                          <Link to="/rigger/pricing">Upgrade Plan</Link>
                        </li>
                      </List>
                    </Grid.Col>
                  </>
                )}
                {[EUserTypes.coach.toString()].includes(currentUser?.type) && (
                  <>
                    <Grid.Col className="grid-col" lg={4} sm={12} xs={12}>
                      <List unstyled={true} className="mb-0">
                        <li>
                          <Link to="/coach/pricing">Upgrade Plan</Link>
                        </li>
                      </List>
                    </Grid.Col>
                  </>
                )}
                {[EUserTypes.admin.toString()].includes(currentUser?.type) && (
                  <>
                    <Grid.Col className="grid-col" lg={4} sm={12} xs={12}>
                      <Text transform="uppercase" color="gray-dark">
                        Tabler-React
                      </Text>
                      <List unstyled={true} className="mb-0">
                        <li>
                          <a
                            href="http://tabler-react.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Demo
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://tabler-react.com/documentation/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Documentation
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://github.com/tabler/tabler-react"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            GitHub repo
                          </a>
                        </li>
                      </List>
                      <Text transform="uppercase" color="gray-dark">
                        Icons
                      </Text>
                      <List unstyled={true} className="mb-0">
                        <li>
                          <a
                            href="https://tabler.io/icons"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Tabler Icons V3
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://feathericons.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Feather Icons
                          </a>
                        </li>
                      </List>
                    </Grid.Col>
                    <Grid.Col className="grid-col" lg={4} sm={12} xs={12}>
                      <Text transform="uppercase" color="gray-dark">
                        FullCalendar
                      </Text>
                      <List unstyled={true} className="mb-0">
                        <li>
                          <a
                            href="https://fullcalendar.io/#demos"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Demo
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://fullcalendar.io/docs"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Documentation
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://github.com/fullcalendar/fullcalendar"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            GitHub repo
                          </a>
                        </li>
                      </List>
                    </Grid.Col>
                    <Grid.Col className="grid-col" lg={4} sm={12} xs={12}>
                      <Text transform="uppercase" color="gray-dark">
                        DEVELOPMENT
                      </Text>
                      <List unstyled={true} className="mb-0">
                        <li>
                          <a
                            href="https://portal.stage.skycruapp.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Portal (STAGE)
                          </a>
                        </li>
                      </List>
                    </Grid.Col>
                  </>
                )}
              </React.Fragment>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col lg={4} sm={12} xs={12} className="grid-col text-right">
            {domainData?.key === EHostnames.FAIRFIGHTS ||
            domainData?.key === EHostnames.COMBAT
              ? 'Combat Sports Digital Credentials Platform'
              : 'Skydiving Community Platform'}
          </Grid.Col>
        </Grid.Row>
      </Container>
    </div>
  )
}

export default FooterNav
