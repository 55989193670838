import cn from 'classnames'
import React from 'react'
import {
  GoogleMap as ReactGoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps'

const MapComponent: React.ElementType = withScriptjs(
  withGoogleMap((props) => (
    <ReactGoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
      <Marker position={{ lat: props.lat, lng: props.lng }} />
    </ReactGoogleMap>
  ))
)

type Props = {
  blackAndWhite: boolean;
  lat: number;
  lng: number;
};

const GoogleMap = ({ blackAndWhite, lat, lng }: Props) => {
  const containerClasses = cn('GoogleMapContainer', { blackAndWhite });
  return (
    <MapComponent
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmr0fj1rhwlThShoaYITVCezJwmkdV4aQ&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div className={containerClasses} />}
      mapElement={<div style={{ height: `100%` }} />}
      lat={lat}
      lng={lng}
    />
  )
};

GoogleMap.defaultProps = {
  blackAndWhite: false
};

export default GoogleMap;
