export const formatMoney = (amount: number, currency?: string) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency ?? 'USD',
    minimumFractionDigits: 2
  })
  return formatter.format(amount)
}

export const getCurrencySymbol = (currency) => {
  return (0)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, '')
    .trim()
}

export default function (n: number) {
  return new Intl.NumberFormat('en-US').format(n)
}

export const formatHeight = (height: number) => {
  const feet = Math.floor(height / 12)
  const inches = Number((height % 12).toFixed(2))

  return `${feet} ft ${inches > 0 ? inches : ''} ${inches > 0 ? ' in' : ''}`
}

export const inchToCm = (inch: number) => {
  return inch * 2.54
}

export const getWingLoad = (canopy, weight) => {
  return (weight / canopy).toFixed(2)
}

export const getAge = (dateString) => {
  const today = new Date()
  const birthDate = new Date(dateString)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export const getWeight = ({ weightInPounds }) => {
  const locale = navigator.language; // Get user's locale

  // Determine if the locale uses metric or imperial
  const usesMetric = !['en-US', 'my-US'].includes(locale); // Add other imperial locales if necessary

  // Convert to metric if needed
  const weight = usesMetric ? weightInPounds * 0.453592 : weightInPounds;
  const unit = usesMetric ? 'kilogram' : 'pound';

  // Format the weight for display
  const formattedWeight = new Intl.NumberFormat(locale, {
    style: 'unit',
    unit,
    unitDisplay: 'long' // Options: 'long', 'short', 'narrow'
  }).format(weight);

  return <span>{formattedWeight}</span>;
};
// Usage
//const App = () => {
//  const weight = userRegistrationDetails?.weight; // Weight in pounds
//  return weight ? <WeightDisplay weightInPounds={weight} /> : <span>No weight provided</span>;
//};

export const getHeight = ({ heightInInches }) => {
  const locale = navigator.language; // Detect user's locale

  // Determine if the locale prefers metric
  const usesMetric = !['en-US', 'my-US'].includes(locale); // Add other imperial locales if needed

  let formattedHeight;
  if (usesMetric) {
    // Convert inches to centimeters (1 inch = 2.54 cm)
    const heightInCm = heightInInches * 2.54;
    formattedHeight = new Intl.NumberFormat(locale, {
      style: 'unit',
      unit: 'centimeter',
      unitDisplay: 'long' // 'long' for "centimeters", 'short' for "cm"
    }).format(heightInCm);
  } else {
    // Convert inches to feet and inches
    const feet = Math.floor(heightInInches / 12);
    const inches = heightInInches % 12;
    formattedHeight = `${feet} ft ${inches} in`;
  }

  return <span>{formattedHeight}</span>;
};
// Usage
//const App = () => {
//  const height = userRegistrationDetails?.height; // Height in inches
//  return height ? <HeightDisplay heightInInches={height} /> : <span>No height provided</span>;
//};


{/*
ALWAYS DISPLAY NUMBERS IN THE FORMAT OF THE LOCAL FORMATTING
const formattedNumber = yourNumber.toLocaleString();
console.log(formattedNumber);

const number = 1234567.89;
console.log(number.toLocaleString()); // Output: "1,234,567.89"

To force result if in Germany (as an example)
const number = 1234567.89;
const formattedNumber = number.toLocaleString('de-DE');
console.log(formattedNumber); // Output: "1.234.567,89"

To force result if in US (as an example)
const number = 1234567.89;
const formattedNumber = number.toLocaleString('en-US');
console.log(formattedNumber); // Output: "1,234,567.89"

  */}
