import gql from 'graphql-tag'

export const GET_COACH_EVENTS = gql`
  query GET_COACH_EVENTS(
    $limit: Int!
    $page: Int!
    $filter: PaginateUserEventsFilter
  ) {
    getAllUserEvents(limit: $limit, page: $page, filter: $filter) {
      userEvents {
        camp_id
        camp_name
        created_on
        end
        first_name
        last_name
        profile_avatar
        slug
        start
        status
        student_id
        user_duration
        user_events_id
        user_registration_id
      }
      page
      totalCount
    }
  }
`
